@font-face {
  font-family: 'SansCN';
  src: url('SourceHanSansCN-Regular-zip.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.app {
  text-align: left;
  /* background-color: #1C0AA3; */
  background-image: url("https://static-cdn.spinq.cn/edu/eduBG.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
iframe {
  z-index: -1 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
main,nav,h1{
  width: 800px;
  margin: 0 auto;
  text-align: center;
  /* margin-top: 200px; */
}
#fixBox{
  position: fixed;
  width: 100vw;
  height: 114px;
  /* background-color: #37347B; */
  top: 0;
  color: #fff;
  z-index: 12;
}
#fixBox a {
  text-decoration: unset;
  color:#fff;
  font-size: 24px;
  margin-right: 10px;
}
.app{
  height: 100vh;
  /* min-height: 100vh; */
}
#nav-bar{
  width: 500px;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
}
#nav-bar *{
  z-index: 12;
}
#main-title{
  font-family:  "SansCN", "sans-serif";
  font-size: 24px;
  text-align: center;
}
html{
  width: 100vw;
  height: 100vh;
  width: 100vw;
  /* border-top: 114px solid #37347B; */
  box-sizing: border-box;
  background-color: transparent;
  font-family:  "SansCN", "sans-serif";
}
html * {
  font-family:  "SansCN", "sans-serif";
}

body,#root{
  width: 100%;
  height: 100%;
}
.nav-tit{
  height: 120px;
  line-height: 120px;
  text-align: center;
  color: #1C2343;
  font-size: 33px;
  position: fixed;
  top: 114px;
  width: 100%;
  background-color: #F2F7FA;
  z-index: 99;
}
.nav-tit .backTo{
  position: absolute;
  height: 120px;
  width: auto;
  padding-left: 32px;
  font-size: 30px;
  color: #575FFC;
  border: none;
  left: 0;
  background: none;
}
.nav-tit .course-helper{
  font-size: 30px;
  color: #575FFC;
  padding-right: 60px;
  height: 120px;
  position: absolute;
  right: 0;
  top: 0;
}
.adm-modal-mask{
  background: unset !important;
}

.bigToast .adm-toast-wrap.adm-toast-wrap-text{
  font-size: 60px;
}

.ant-tooltip{
  max-width: 650px !important;
}

.ant-tooltip-content .ant-tooltip-inner{
  background-color: #fff;
  color: #32325D;
  padding: 20px;
  font-size: 20px;
  border-radius: 10px;
}

.ant-tooltip-content .ant-tooltip-arrow-content{
  --antd-arrow-background-color: unset;
  box-shadow: unset !important;
}

@-webkit-keyframes myRotate{
  0%{ -webkit-transform: rotate(0deg);transform-origin: top left;}
  50%{ -webkit-transform: rotate(180deg);transform-origin: top left;}
  100%{ -webkit-transform: rotate(360deg);transform-origin: top left;}
}
@keyframes myRotate{
  0%{ -webkit-transform: rotate(0deg);transform-origin: top left;}
  50%{ -webkit-transform: rotate(180deg);transform-origin: top left;}
  100%{ -webkit-transform: rotate(360deg);transform-origin: top left;}
}
@keyframes activeBlockAnimate{
  0% {
    opacity:1;
  }
  20%{
    opacity:0.8;
  }
  40%{
    opacity:0.3;
  }
  60%{
    opacity:0.4;
  }
  80%{
    opacity:0.8;
  }
  100%{
    opacity:1;
  }
}