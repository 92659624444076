:global{
  .task-detail-nav.nav-tit{
    background-color: transparent;
    color: #fff;
    position: absolute;
    width: 75%;
    margin: 0 auto;
    left: 50%;
    height: 90px;
    line-height: 90px;
    transform: translateX(-50%);
    .returnButton-wrapper{
      height: 90px;
      line-height: 90px;
      display: block;
      width: 100%;
      top: 0;
      position: relative;
      .returnButton{
        font-size: 24px;
        text-align: left;
        
      }
      .title{
        color: #fff;
        position: absolute;
        height: 90px;
        line-height: 90px;
        font-size: 24px;
        vertical-align: top;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .course-helper{
      color: #10B2FD;
      padding-left: 30px;
      font-size: 24px;
      cursor: pointer;
      .helper-content{
        position: relative;
        &::before{
          content: '';
          width: 40px;
          height: 30px;
          background-image: url("./img/TaskDetail/lesson.png");
          filter: invert(48%) sepia(88%) saturate(2256%) hue-rotate(176deg) brightness(110%) contrast(98%);
          background-size: 100% 100%;
          display: block;
          position: absolute;
          // right: 160px;
          left: -63px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .backTo{
      span{
        margin-left: 20px;
        white-space: pre-wrap;
      }
    }
  }
  .task{
    height: 100%;
    transform: scale(0.75);
    margin-top: 80px;
    padding-top: 0;

    .task-content{
      height: 100%;
      padding: 0 0 0 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .unitSub{
        vertical-align: sub;
        font-size: 24px;
      }
      .task-list{
        height: 100%;
        background-color: #fff;
        width: 500px;
        box-sizing: border-box;
        overflow-y: scroll;
        .list-item{
          padding: 0 34px;
          width: 100%;
          border: none;
          background: none;
          height: 274px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          *{
            text-align: left;
            user-select:none;
          }
          .rightArr{
            display: block;
            position: absolute;
            width: 28px;
            height: 38px;
            right: 50px;
            top: 50%;
            transform: translateY(-50%);
            color: #343F4D;
            font-size: 40px;
            margin-top: -5px;
          }
          .item-content{
            width: 100%;
            padding-left: 20px;
            position: relative;
          }
          .big-tit{
            font-size: 58px;
            height: 64px;
            line-height: 64px;
            font-weight: 300;
          }
          .big-tit.failed{
            color: #E71F0F;
          }
          
          .status{
            font-size: 30px;
            position: absolute;
            right: 30px;
            top: 0;
            line-height: 64px;
            height: 64px;
            font-weight: bold;
          }
          .complete{
            color: #21AE0A;
            img{
              vertical-align: middle;
              margin-top: -5px;
              margin-left: 10px;
              width: 33px;
            }
          }
          .failed{
            color: #E71F0F;
            // right: 150px;
          }
          .sub-tit{
            color: #343F4D;
            font-size: 32px;
            margin-top: 30px;
            font-weight: 400;
          }
        }
        .list-item:not(:last-child){
          &::after{
            position: absolute;
            bottom:0;
            width: 432px;
            left: 34px;
            height: 1px;
            content: '';
            background-color: #8F9DB1;
          }
          // border-bottom: 1px solid #8F9DB1;
        }
        .list-item.active{
          background-color: #E3F0FF;
        }
      }
      .task-detail{
        height: 100%;
        margin-left: 16px;
        flex: 1;
        background-color: #fff;
        overflow-y: scroll;
        .answer-sheet{
          padding: 50px 50px 0 50px;
          position: relative;
          .circuitIns{
            width: 100%;
            height: 40px;
            // background-color: #f2f2f2;
            text-align: left;
            line-height: 40px;
            margin-bottom: 30px;
            font-size: 36px;
            img{
              height: 130%;
            }
          }
          h1{
            color: #3D4855;
            font-weight: bold;
            font-size: 40px;
            text-align: left;
            width: 100%;
          }
          .imageList{
            padding: 20px 0;
            .image-item{
              display: block;
              margin: 20px auto;
              min-height: 160px;
              background-size: auto 100%;
              background-repeat: no-repeat;
              max-width: 800px;
            }
            .imageTips{
              margin: 40px;
              font-size: 30px;
              text-align: center;
              color: #7E8998;
            }
            .image-item.left{
              margin-left: 0;
            }
            
          }
          .sub-tit{
            color: #333;
            font-size: 32px;
            line-height: 50px;
            margin: 28px 0;
            .gray{
              color: #7E8998;
            }
          }
          .min-tit-5-2{
            color: #999;
          }
          .prob-table-wrap{
            display: flex;
            justify-content: space-around;
          }
          .prob-table{
            margin-top: 46px;
            display: inline-block;
            max-width: 1496px;
            .prob-header{
              height: 236px;
              display: flex;
              .table-type{
                width: 480px;
                background-color: #6D79F9;
                color: #fff;
                font-size: 39px;
                line-height: 236px;
                text-align: center;
              }
              .prob-list{
                height: 236px;
                margin-left: 16px;
                flex:1;
                .prob-tit{
                  height: 110px;
                  text-align: center;
                  line-height: 110px;
                  background-color: #4643B5;
                  font-size: 39px;
                  color: #fff;
                }
                .prob-itemList{
                  margin-top: 16px;
                  display: flex;
                  justify-content: space-between;
                  height: 110px;
                  width: 100%;
                  .prob-item{
                    display: block;
                    // width: 238px;
                    line-height: 110px;
                    flex:1;
                    text-align: center;
                    background-color: #6E93F3;
                    color: #fff;
                    font-size: 36px;
                  }
                  .prob-item:not(:last-child){
                    margin-right: 16px;
                  }
                }
              }
            }
            .prob-body{
              .prob-line{
                display: flex;
                justify-content: space-between;
                margin-top: 16px;
                .prob-left-tit{
                  width: 480px;
                  height: 122px;
                  line-height: 122px;
                  font-size: 36px;
                  text-align: center;
                  color: #fff;
                  background-color: #6D79F9;
                }
                .prob-right-content{
                  display: flex;
                  margin-left: 16px;
                  width: 1000px;
                  .inputWrap{
                    flex: 1;
                    box-sizing: border-box;
                  }
                  
                  input{
                    // width: 238px;
                    width: 100%;
                    display: block;
                    height: 122px;
                    line-height: 122px;
                    border: 1px solid #CBD5E9;
                    background-color: #E8EEFA;
                    box-sizing: border-box;
                    font-size: 36px;
                    color: #343F4D;
                    text-align: center;
                    color: #006699;
                  }
                  .inputWrap:not(:last-child) {
                    margin-right: 16px;
                  }
                }
              }
            }
          }
          .table-wrap{
            max-width: 1500px;
            margin: 40px auto;
            .fillTableWrapper{
              height: 60px;
            }
            .tab-tips{
              margin-bottom: 40px;
              color: #999;
              font-size: 30px;
            }
            .tableTipsFor6-2{
              position: absolute;
              left: 0;
              padding: 0 50px;
              margin-bottom: 40px;
              color: #999;
              font-size: 30px;
              line-height: 40px;
            }
            .tips{
              height: 80px;
              line-height: 80px;
              font-size: 40px;
              text-align: left;
            }
            .tips.task1_4{
              text-align: left;
            }
            .header-wraper{
              width: 100%;
            }
            .table-flex-header{
              width: 100%;
              display: flex;
              .left-header,.right-header{
                flex: 1;
              }
              .left-header{
                .table-header-item{
                  height: 258px;
                  line-height: 258px;
                  margin-right: 14px;
                }
              }
            }
            .table-header{
              display: flex;
              width: 100%;
              .table-header-item{
                // max-width: 440px;
                flex: 1;
                background-color: #4643B5;
                color: #fff;
                height: 122px;
                font-size: 34px;
                margin-bottom: 14px;
                line-height: 122px;
                text-align: center;
                word-break: keep-all;
                box-sizing: border-box;
                .minfont{
                  font-size: 20px;
                }
              }
              .table-header-item:not(:last-child){
                margin-right: 14px;
                border-top: none;
              }
            }
            .table-header:nth-child(2){
              .table-header-item{
                background-color: #6f77fb;
              }
            }
            .table-body{
              .table-line {
                display: flex;
                .table-side-item{
                  max-width: 440px;
                  flex: 1;
                  background-color: #6E93F3;
                  color: #fff;
                  font-size: 36px;
                  font-weight: 400;
                  line-height: 122px;
                  height: 122px;
                  text-align: center;
                  margin-right: 14px;
                  box-sizing: border-box;
                }
                .table-body-item{
                  z-index: 10;
                  max-width: 440px;
                  flex: 1;
                  height: 122px;
                  background-color: #E8EEFA;
                  font-size: 36px;
                  text-align: center;
                  border: 1px solid #CBD5E9;
                  border-radius: 3px;
                  line-height: 122px;
                  box-sizing: border-box;
                  .inputWrap{
                    height: 100%;

                  }
                  input{
                    width: 100%;
                    height: 100%;
                    display: block;
                    background-color: transparent;
                    border: none;
                    font-size: 36px;
                    text-align: center;
                    color: #006699;
                  }
                  input.error{
                    border: 1px solid #D91B0D;
                    color: #D91B0D;
                    background-color: #fff;
                  }
                  input:disabled{
                    color: #333;
                  }
                }
                .table-body-item:not(:last-child){
                  margin-right: 14px;
                  box-sizing: border-box;
                }
              }
              .table-line:not(:last-child){
                margin-bottom: 14px;
              }
              .table-line.paddingbottom{
                padding-bottom: 90px;
                position: relative;
                .break-word-line{
                  position: absolute;
                  bottom: 0;
                }
              }
            }
            .singleTask{
              margin-top: 40px;
              margin-bottom: 80px;
              font-size: 30px;
              .item-topic{
                font-size: 36px;
              }
              .item-content{
                margin-top: 30px;
                padding: 0 76px;
                display: flex;
                justify-content: space-between;
                .minfont{
                  font-size: 20px;
                  line-height: 80px;
                  vertical-align: bottom;
                }
                .label{
                  height: 60px;
                  line-height: 60px;
                  display: flex;
                  font-size: 36px;
                }
                .left-part,.right-part{
                  width: 700px;
                }
                .inputWrap{
                  display: inline-block;
                  width: 120px;
                  height: 60px;
                  position: relative;
                  .error{
                    border-color: #D91B0D;
                    color: #D91B0D;
                  }
                }
                .inputWrap.disableInput{
                  input{
                    color: #333 !important;
                    background: none;
                  }
                  
                }
                .left-part{
                  input{
                    width: 100%;
                    border: none;
                    overflow: visible;
                    position: relative;
                    height: 60px;
                    font-size: 40px;
                    border-bottom: 1px solid #333;
                    text-align: center;
                    color: #0066CC;
                  }
                  .inputWrap{
                    padding: 0 20px;
                    &::before{
                      display: inline-block;
                      content: "|";
                      width: 20px;
                      height: 60px;
                      position: absolute;
                      left: 10px;
                      top: 0;
                      color: #000;
                      z-index: 3;
                    }
                    &::after{
                      display: inline-block;
                      content: ">";
                      position: absolute;
                      right: 0;
                      top: 55%;
                      transform: translateY(-50%);
                      width: 20px;
                      height: 60px;
                      vertical-align: middle;
                    }
                  }
                }
                .right-part{
                 .inputWrap{
                  padding: 0 20px;
                  .error{
                    border-color: #D91B0D;
                  }
                  input{
                    border: none;
                    width: 100%;
                    height: 60px;
                    border-bottom: 1px solid #333;
                    font-size: 40px;
                    color: #0066CC;
                    text-align: center;
                   }
                 } 
                }
              }
            }
          }
          
          .showDirac.table-wrap{
            .table-body-item{
              position: relative;
              &::after{
                content: "";
                width: 80px;
                position: absolute;
                bottom: 30px;
                height: 2px;
                left: 80px;
                display: block;
                background-color: #006699;
              }
            }
            .inputWrap{
              
              position: relative;
              &::before{
                position: absolute;
                content:"|";
                display: block;
                width: 20px;
                height: 120px;
                line-height: 120px;
                left: 60px;
                top: 0;
                color: #006699;
              }
              &::after{
                position: absolute;
                content:">";
                display: block;
                width: 20px;
                height: 120px;
                left: 170px;
                top: 0px;
                color: #006699;
              }
            }
          }
          .table-wrap:not(:last-child){
            margin-bottom: 100px;
          }
          .table-wrap:nth-last-child(2){
            margin-bottom: 260px;
          }
          .justOneChoiceAlignLeft.MultiMultipleChoice{
            .multiple-choice-wrap .choice-wrap .txt-choice{
              padding-left: 0;
            }
          }
          .MultiMultipleChoice{
            margin-top: 80px;
          }
          // Multiple Choice style
          .multiple-choice-wrap {
            margin-bottom: 100px;
            .tips {
              padding: 21px 0px;
              margin-bottom: 60px;
              font-size: 40px;
              line-height: 55px;
              display: flex;
              .gray{
                color: #7E8998;
              }
            }
            .choice-wrap {
              overflow: hidden;
              &:not(:last-child) {
                margin-bottom: 80px;
              }

              .card-choice {
                display: inline-block;
                font-size: 40px;
                padding: 20px;
                border-radius: 8PX;
                border: 1px solid #333333;
                text-align: center;
                color: #3082FC;
                min-width: 200px;
                &.selected {
                  background-color: #3082FC;
                  border: 1px solid #3082FC;
                  color: #FFFFFF;
                  &.error {
                    background-color: #ff4200;
                    border: 1px solid #ff4200;
                  }
                }
              }
              .txt-choice {
                padding-left: 102px;
                .adm-checkbox-icon {
                  display: none;
                }
                .adm-checkbox-content {
                  font-size: 40px !important;
                  padding-left: 20px !important;
                }
                &.adm-checkbox-checked {
                  color: #3082FC !important;
                  &.error {
                    color: #D91B0D !important;
                    .adm-checkbox-icon {
                      border-color: #D91B0D  !important;
                      background-color: #D91B0D  !important;
                    }
                  }
                }
              }

              &.inline-choice {
                display: inline-block;
                &:not(:last-child) {
                  margin-bottom: 0px;
                }
                &:not(:first-child) {
                  margin-left: 60px;
                }
                .txt-choice {
                  padding: 20px 0px;
                }
              }
            }
            .choice-wrap {
              .txt-choice{
                &::before{
                  content: 'A';
                  text-align: center;
                  line-height: 54px;
                  width: 54px;
                  height: 54px;
                  border-radius: 50%;
                  font-size: 40px;
                  border: 1px solid #7E8998;
                }
              }
              .adm-checkbox-checked.txt-choice{
                &::before{
                  color: #fff;
                  background-color: #3082FC;
                }
              }
              .adm-checkbox-checked.txt-choice.error{
                &::before{
                  color: #fff;
                  background-color: #D91B0D;
                }
              }
            }
            .choice-wrap:nth-child(2){
              .txt-choice{
                &::before{
                  content: 'B';
                }
              }
            }
            .choice-wrap:nth-child(3){
              .txt-choice{
                &::before{
                  content: 'C';
                }
              }
            }
            .choice-wrap:nth-child(4){
              .txt-choice{
                &::before{
                  content: 'D';
                }
              }
            }
            .choice-wrap:nth-child(5){
              .txt-choice{
                &::before{
                  content: 'E';
                }
              }
            }
            &.inline-wrap {
              .tips {
                height: 82px;
                line-height: 82px;
                padding: 0;
                float: left;
                margin-bottom: 0px;
                margin-right: 60px;
              }
            }
          }
          .Fill-blank{
            margin-bottom: 100px;
            .tab-list{
              font-size: 36px;
              .tab-ins{
                line-height: 50px;
              }
              .tab-tips{
                font-size: 30px;
                color: #7E8998;
                margin-top: 30px;
              }
              .tab-item-ls{
                display: flex;
                width: 100%;
                margin-top: 30px;
                .tab-item{
                  height: 100px;
                  line-height: 100px;
                  color: rgb(0, 102, 204);
                  background-color: #e7eefc;
                  padding: 0 30px;
                  margin-right: 20px;
                  letter-spacing: 2px;
                  font-size: 36px;
                }
                .active-item-in-bar{
                  box-sizing: border-box;
                  outline: 2px solid #006699;
                }
              }
            }
            .error{
              .vector-item{
                &::before,&::after{
                  border-color: #D91B0D;
                }
              }
            }
            .vector-item{
              height: auto !important;
              position:relative;
              padding: 20px;
              display: inline-block;
              vertical-align: middle;
              &::before,&::after{
                content: "";
                position: absolute;
                top: 0;
                border: 1px solid #0066cc;
                width: 6px;
                height: 80%;
                top: 10%;
              }
              &::before{
                left: 4px;
                border-right: 0px;
              }
              &::after{
                right: 4px;
                border-left: 0px;
              }
              .line{
                text-align: center;
                min-width: 30px;
                text-align: center;
                height: 50px;
                line-height: 50px;
                .line-content{
                  display: inline-block;
                  width: 60px;
                }
              }
            }
            .vector-item.mt50{
              margin-top: 50px;
              margin-bottom: 50px;
            }
            .vector-filled{
              min-height: 140px;
              height: auto !important;
              vertical-align: middle;
            }
            .input-list{
              margin-top: 100px;
              .input-Item{
                width: 50%;
                display: flex;
                margin-bottom: 80px;
                align-items: center;
                .input-name-part{
                  font-size: 40px;
                  
                  width: 200px;
                  display: block;
                  .tag{
                    // min-width: 520px;
                    display: inline-block;
                    vertical-align: top;
                    .minfont{
                      font-size: 20px;
                      line-height: 110px;
                    }
                  }
                }
                .input-name-part.unlimitedName{
                  width: auto;
                  height: 100%;
                  .tag{
                    margin-right: 20px;
                    // min-width: 800px;
                  }
                }
                .input-unit{
                  // height: 100px;
                  display: inline-block;
                  vertical-align: middle;
                  // margin-right: 40px;
                  .error.unit-symb,.error.unit-val{
                    border: 1px solid #D91B0D;
                    background-color: #fff;
                    color: #D91B0D;
                  }
                  .unit-val{
                    width: 160px;
                    border: 1px dashed #333;
                    height: 100px;
                    display: inline-block;
                    box-sizing: border-box;
                    vertical-align: top;
                    font-size: 36px;
                    line-height: 100px;
                    text-align: center;
                    color: #0066cc;
                    position: relative;
                  }
                  .showDelContent{
                    display: none;
                  }
                  .filled{
                    &:hover{
                      .showDelContent{
                        display: block;
                        content: '+';
                        transform: rotate(45deg);
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        border-radius: 15px;
                        background-color: red;
                        color: #fff;
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                        right: -10px;
                        top: -10px;
                        z-index: 9;
                      }
                    }
                  }
                  .active.unit-symb,.active.unit-val{
                    border: 2px solid #0066cc;
                    box-sizing: border-box;
                  }
                  .filled{
                    background-color: #e7eefc;
                  }
                }
                .vector-item{
                  &::before,&::after{
                    border-color: #000;
                  }
                }
              }
              .input-Item:nth-child(n+2) {
                .unlimitedName .tag{
                  // min-width: 520px;
                }
              }
            }
            .integrated {
              position: relative;
              .status-List{
                width: 1050px;
                // justify-content: space-between;
                margin-bottom: 30px;
                .status-Item{
                  width: 150px;
                  display: inline-block;
                  vertical-align: top;
                  font-size: 32px;
                  .minfont{
                    font-size: 14px;
                  }
                }
                .status-Item:first-child{
                  padding-left: 40px;
                }
                .status-Item:nth-of-type(2){
                  margin-left: 60px;
                }
                .status-Item:nth-of-type(3){
                  margin-left: 90px;
                }
                .status-Item:nth-of-type(4){
                  margin-left: 90px;
                }
              }
              .input-Item{
                width: 100%;
                .input-unit{
                  margin-right: 0;
                }
                .placeholderAfter{
                  &::after{
                    content:"→";
                    width:80px;
                    line-height: 100px;
                    height: 100px;
                    display: inline-block;
                    text-align: center;
                    font-size: 40px;
                  }
                }
              }
            }
            *{
              user-select: none;
            }
          }
          .Fill-blank.task6_3{
            // width: 1200px;
            // margin: 0 auto;
            padding-top: 100px;
            position: relative;
            // &::before{
            //   content: "示例：";
            //   position: absolute;
            //   top: 0;
            //   font-size: 30px;
            //   line-height: 100px;
            //   color: #999;
            // }
            .tab-ins{
              margin-bottom: 60px;
            }
          }
          .highLightFinnal.Fill-blank{
            .input-Item:last-child{
              color: #006699;
            }
          }
          .DesignAndComputeCircuit{
            display: flex;
            .left-part{
              flex:3;
            }
            .gateContainer{
              margin: unset;
            }
            .circuitBoard{
              min-height: 400px;
              margin-bottom: 200px;
              .deletingFlag{
                bottom: -80px;
                left: 80%;
              }
            }
            .right-part{
              flex: 2;
              padding-top: 120px;
              font-size: 40px;
              padding-left: 80px;
              box-sizing: border-box;
              .res-item{
                display: flex;
                height: 80px;
                margin-bottom: 80px;
                width: 100%;
                .item-left{
                  height: 100%;
                  span{
                    line-height: 80px;
                  }
                  .minfont{
                    font-size: 14px;
                  }
                }
                .item-right{
                  margin-left: 20px;
                  height: 100%;
                  vertical-align: bottom;
                  input{
                    width: 400px;
                    display: block;
                    height: 80px;
                    border: 2px solid transparent;
                    box-sizing: border-box;
                    font-size: 40px;
                    text-align: center;
                    background-color: #E8EEFA;
                    color: #006699;
                  }
                  .error{
                    border-color: #D91B0D;
                    color: #D91B0D;
                  }
                }
              }
            }
          }
        }
        .answer-sheet.answer-sheet-6_1{
          .image-wrap:nth-child(1) .image-item{
            height: 186px;
            width: auto;
            max-width: unset;
          }
        }

        .answer-sheet.answer-sheet-6_3,.answer-sheet.answer-sheet-6_4,.answer-sheet.answer-sheet-6_5,.answer-sheet.answer-sheet-6_6{
          .image-item{
            height: 286px;
            width: auto;
            max-width: unset;
          }
        }
        .answer-sheet.answer-sheet-9_1{
          .image-wrap .image-item{
            height: 176px;
            width: auto;
            max-width: unset;
          }
          .Fill-blank .input-list .input-Item .input-unit .unit-val{
            width: 160px;
            height: 240px;
          }
        }
        .answer-sheet.answer-sheet-5_2,.answer-sheet.answer-sheet-5_3,.answer-sheet.answer-sheet-5_4,.answer-sheet.answer-sheet-5_5{
          .table-wrap {
            padding-top: 80px;
            .tips{
              height: 80px;
              padding-left: 50px;
              box-sizing: border-box;
              position: absolute;
              width: 100%;
              left: 0;
              margin-top: -80px;
              font-size: 36px;
            }
          }
        }
        .answer-sheet.animateStateFor1_3{
          .table-header-item:not(:nth-child(3)):not(:last-child) {
            filter: opacity(0.1);
          }
          .table-body-item:not(:nth-child(3)):not(:last-child) {
            filter: opacity(0.1);
          }
          .table-side-item {
            filter: opacity(0.1);
          }
          .table-header-item:nth-child(3){
            -webkit-animation: activeBlockAnimate 2 1s linear;;
            animation: activeBlockAnimate 2 1s linear;;
          }
          .table-header-item:last-child{
            -webkit-animation: activeBlockAnimate 2 1s linear;;
            animation: activeBlockAnimate 2 1s linear;;
          }
          .table-body-item:nth-child(3){
            -webkit-animation: activeBlockAnimate 2 1s linear;;
            animation: activeBlockAnimate 2 1s linear;;
          }
          .table-body-item:last-child{
            -webkit-animation: activeBlockAnimate 2 1s linear;;
            animation: activeBlockAnimate 2 1s linear;;
          }
        }
        .answer-sheet.answer-sheet-8_1{
          .image-wrap .image-item{
            height: 326px;
            width: auto;
            max-width: unset;
          }
        }
        .answer-sheet.answer-sheet-10_1{
          .Fill-blank .integrated .input-Item .input-unit{
            .unit-val.vector-filled{
              height: 240px !important;
              box-sizing: border-box;
            }
          }
        }
        .answer-sheet.answer-sheet-13_1{
          .imageList{
            padding: 0 100px;
            justify-content: space-around;
            display: flex;
            flex-wrap:wrap;
            .image-wrap:nth-child(even){
              margin-top: 42px;
            }
          }
        }
        .answer-sheet.answer-sheet-13_2,.answer-sheet.answer-sheet-13_3{
          .imageList .image-item {
            max-width: unset;
            max-height: 380px;
          }
        }
        .answer-sheet.answer-sheet-14_1{
          .imageList .image-item {
            max-width: 1020px;
          }
        }
        .answer-sheet.answer-sheet-14_2{
          .imageList .image-item {
            max-height: 450px;
          }
        }
        .answer-sheet.answer-sheet-15_1 {
          .MultiMultipleChoice:nth-child(4){
            margin-top: 25px;
          }
        }
        .answer-sheet.answer-sheet-16_1{
          .DesignAndComputeCircuit{
            display: block;
            .circuitBoard{
              margin-bottom: 0;
            }
            .right-part{
              padding-left: 0;
              padding-top: 0;
              .res-item .item-right{
                position: relative;
                padding: 0 30px;
                border: none;
                &::before{
                  content: "|";
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
                &::after{
                  content: ">";
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
                input{
                  width: 120px;
                  border: none;
                  border-bottom: 1px solid #000;
                  background-color: transparent;
                }
                .error{
                  border-color: #E71F0F;
                }
              }
            }
          }
        }
        .submit-line{
          display: flex;
          margin:260px 0;
          justify-content: space-around;
          transition: all .2s;
          opacity: 0;
        }
        .submit-line.active{
          opacity: 1;
          transition-delay: .1s;
        }
        .submit{
          display: block;
          width: 1500px;
          height: 122px;
          border:unset;
          border-radius: 5px;
          background-color: #21AE0A;
          font-size: 40px;
          font-weight: 400;
          color: #fff;
        }
        .DecimalComponent{
          width: 100%;
          display: flex;
          margin-bottom: 260px;
          .table-wrap{
            flex: 1;
            .table-header-item{
              max-width: 440px;
              
            }
          }
          .break-word-line{
            display: flex;
            width: 100%;
            .break-word-block{
              width: 440px;
              font-size: 50px;
              text-align: center;
              transform: rotate(90deg);
              height: 60px;
              line-height: 30px;
              margin-bottom: 14px;
            }
          }
        }
        .ArithmeticComponent{
          margin-bottom: 260px;
          padding: 0 10px;
          .ArithmeticLine{
            display: flex;
            justify-content: space-between;
            margin-bottom: 80px;
          }
          .ArithmeticItem{
            width: 700px;
            .line{
              font-size: 60px;
              height: 100px;
              line-height: 100px;
              text-align: right;
              position: relative;
              color: #333;
              .symb{
                position: absolute;
                right: 300px;
                top: 0;
              }
              .symb.multiplication{
                transform: rotate(45deg);
              }
            }
            .line.diversion{
              text-align: left;
              border-top: 1px solid #7E8998;
              border-left: 1px solid #7E8998;
              color: #333;
              margin-bottom: 20px;
              padding-left: 20px;
              .symb{
                right: 105%;
              }
            }
            .ignoreProcess{
              font-size: 40px;
              color: #7E8998;
            }
            .result{
              border-top: 1px solid #333;
              box-sizing: border-box;
              input{
                width: 100%;
                height: 100px;
                line-height: 100px;
                margin-top: 20px;
                box-sizing: border-box;
                border: none;
                background-color: #E3F0FF;
                text-align: right;
                color: #333;
                font-size: 60px;
              }
              input.error{
                border: 1px solid #D91B0D;
                color: #D91B0D;
                background-color: #fff;
              }
            }
            .result.top{
              border-top: none;
            }
          }
          .diversionItem.ArithmeticItem{
            .result input{
              text-align: left;
              padding-left: 20px;
            }
          }
        }
        .MutiplicationComponent{
          font-size: 40px;
          input{
            border: none;
            height: 80px;
            line-height: 80px;
            border-bottom: 2px solid #000;
            font-size: 40px;
            text-align: center;
            width: 100px;
            color: #006699;
          }
          input.error{
            border-color: #D91B0D;
            color: #D91B0D;
          }
          .vectorMultiplication{
            display: flex;
            &>{
                display: flex;
                align-items: center;
            }
            .leftInput span{
              display: flex;
            }
            .symbol{
              display: flex;
              align-items: center;
              // padding: 0 20px;
              justify-content: center;
              width: 90px;
              text-align: center;
            }
            .midInput{
              display: flex;
            }
            .matrix{
              display: flex;
              flex-direction: column;
              padding: 0 50px;
              border-left: 2px solid #000;
              border-right: 2px solid #000;
              .inputBox{
                &:first-child,&:last-child{
                  position: relative;
                  &::before,&::after{
                    width: 10px;
                    height: 2px;
                    position: absolute;
                    top: 0;
                    left: -50px;
                    content: '';
                    background-color: #000;
                  }
                  &::after{
                    left: unset;
                    right: -50px;
                  }
                }
                &:last-child{
                  &::before,&::after{
                    top: unset;
                    bottom: 0;
                  }
                }
              }
              
            }
            .result{
              .inputBox{
                position: relative;
                padding-left: 20px;
                input{
                  border: none;
                  border-bottom: 2px solid #000;
                }
                &::before{
                  position: absolute;
                  content: '|';
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
                &::after{
                  position: absolute;
                  content: '>';
                  right: -40px;
                  top: 50%;
                  transform: translateY(-50%);
                }
                .error{
                  border-color: #D91B0D;
                }
              }
            }
          }
          .vectorMultiplication:not(:last-child){
            padding-bottom: 100px;
          }
          .task11_2_container{
            .leftInput{
              width: 380px;
            }
            .vectorMultiplication:nth-child(n+2) {
              padding-left: 380px;
            }
          }
          .task11_3_container{
            .lefthand{
              input{
                border-bottom: none;
                text-align: center;
              }
              input:disabled{
                background-color: #fff;
                width: 70px;
                color: #000;
              }
            }
            
            .midInput{
              flex-wrap: wrap;
              padding: 0 50px;
              border-left: 2px solid #000;
              border-right: 2px solid #000;
              position: relative;
              .matrixContainer{
                padding-left: 120px;
                input:last-child{
                  margin-left: 20px;
                }
                .matrix:first-child{
                  margin-bottom: 20px;
                }
                .coefficient{
                  position: absolute;
                  width: 100px;
                  height: 80px;
                  left: -80px;
                  top: 45px;
                  
                }
                .coefficient1{
                  left: 50px;
                }
                .coefficient2{
                  right: 390px;
                  left: unset;
                }
                .coefficient3{
                  left: 50px;
                  top: 225px;
                }
                .coefficient4{
                  right: 390px;
                  top: 225px;
                  left: unset;
                }
              }
              .matrixContainer:first-child,.matrixContainer:last-child{
                &::before,&::after{
                  content: '';
                  width: 10px;
                  height: 2px;
                  background-color: #000;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                }
                &::after{
                  right: 0;
                  left: unset;
                }
              }
              .matrixContainer:first-child{
                &::before{
                  top: 0;
                  bottom: unset;
                  right: unset;
                }
                &::after{
                  top: 0;
                  bottom: unset;
                  left: unset;
                }
              }
              .matrixContainer:last-child{
                padding-left: 170px;
              }
            }
            .specialMidInput{
              .inputBox:nth-child(2){
                position: relative;
                &::before,&::after{
                  content: '';
                  width: 10px;
                  height: 2px;
                  background-color: #000;
                  position: absolute;
                  top: 0;
                  left: -50px;
                }
                &::after{
                  right: -50px;
                  left: unset;
                }
              }
            }
            .vectorMultiplication2{
              padding-left: 578px;
              .inputBox{
                input:not(:first-child){
                  margin-left: 20px;
                }
              }
            }
          }
        }
        .SimpleTextInputComponent{
          font-size: 32px;

          input{
            border: none;
            border-bottom: 2px solid #000;
            position: relative;
            font-size: 32px;
            text-align: center;
            width: 120px;
            height: 70px;
            box-sizing: border-box;
            color: #006699;
          }
          .error{
            border-color: #D91B0D;
            color: #D91B0D;
          }
          .subTips{
            display: flex;
            align-items: center;
            input:disabled{
              background-color: transparent;
              color: #000;
            }
          }
          .tips,.reverseList{
            margin-top: 60px;
            input:not(:first-child){
              margin-right: 20px;
            }
            .bra{
              margin-right: 30px;
            }
          }
          .tips{
            margin-top: 30px;
            transform: translateY(34px);
          }
          .avg{
            margin-top: 30px;
          }
          .matrixRight.inputContainer{
            display: flex;
            width: 1000px;
            justify-content: space-between;
            .avg{
              margin-top: 0;
              display: flex;
              align-items: center;
              input{
                margin-left: 20px;
              }
            }
            .reverseList{
              margin-top: 0;
              display: flex;
              align-items: center;
              .matrix{
                margin-left: 20px;
                padding: 0 50px;
                border-left: 2px solid #000;
                border-right: 2px solid #000;
                position: relative;
                .inputBox{
                  &::before,&::after{
                    content: '';
                    width: 10px;
                    height: 2px;
                    background-color: #000;
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                }
                .inputBox:nth-child(1){
                  &::after{
                    right:0;
                    left: unset;
                  }
                }
                .inputBox:last-child{
                  &::before{
                    bottom: 0;
                    top: unset;
                    left: 0;
                  }
                  &::after{
                    right:0;
                    bottom: 0;
                    top: unset;
                    left: unset;
                  }
                }
              }
            }
          }
          .smalltopic{
            .topic{
              display: flex;
              align-items: center;
              margin-top: 60px;
              padding-left: 20px;
              .inputer{
                display: flex;
                input{
                  width: 200px;
                }
                p{
                  margin-right: 220px;
                }
              }
            }
          }
          .smalltopic:nth-child(n+2){
            margin-top: 120px;
          }
        }
      }
    }
  }
  .task-detail-modal{
    
    .adm-modal-wrap,.adm-center-popup-wrap{
      width: 1070px;
      height: 845px;
      border-radius: 10px;
      .adm-modal-body{
        box-shadow: 0px 8px 32px 3px rgba(83, 94, 105, 0.89);
        background: #F6F9FC;
        max-height: 75vh;
      }
      .img-container{
        position: relative;
        span{
          font-size: 48px;
          font-weight: 400;
          position: absolute;
          z-index: 9;
          bottom: 80px;
          left: 50%;
          transform: translateX(-50%);
          color: #fff;
        }
      }
      img{
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 520px;
        height: 485px;
      }
      .success{
        width: 620px;
        height: 540px;
      }
      p{
        text-align: center;
        font-size: 44px;
        color: #3A5AA1;
      }
      
      // background-color: unset;
    }
    .adm-modal-close.adm-plain-anchor{
      top: 40px;
      right: 40px;
      width: 40px;
      height: 40px;
      color: #575FFC;
      *{
        width: 40px;
        height: 40px;
      }
    }
    .adm-modal-footer{
        flex-direction: row;
        justify-content: space-around;
        margin-top: 60px;
        padding-bottom: 70px;
        .adm-space-item{
          width: 400px;
          height: 80px;
          border-radius: 5px;
          button{
            height: 100%;
            line-height: 1;
            font-size: 36px;
          }
        }
        .confirm,.back{
          background-color: #575FFC;
          color: #fff;
        }
        .cancel{
          background-color: #fff;
          color: #575FFC;
        }
    }
  }
  // 1-3题的单独弹窗
  .tipsAlert{
    .adm-modal-wrap{
      width: 900px;
    }
    .adm-modal-body{
      padding: 0;
      .adm-modal-header{
        width: 100%;
        display: block;
        padding: 0;
        .adm-auto-center{
          width: 100%;
          display: block;
          .adm-auto-center-content{
            display: block;
          }
        }
      }
    }
    .task1_3Header{
      height: 110px;
      padding-left: 40px;
      line-height: 110px;
      background-color: #575FFC;
      color: #fff;
      text-align: left;
      width: 100%;
      font-size: 40px;
      margin-bottom: 60px;
    }
    .adm-modal-title{
      font-size: 30px;
      margin-bottom: 40px;
    }
    .adm-modal-content{
      font-size: 40px;
      color: #0066CC;
      font-weight: normal;
      text-align: center;
      div{
        margin-bottom: 30px;
      }
    }
    .adm-modal-footer{
      width: 300px;
      margin: 30px auto;
      .adm-modal-button{
        font-size: 30px !important;
        height: 80px;
        background-color: #575FFC;
      }
    }
  }
  .enApp .task .task-content {
    .task-list .list-item .status.failed{
      right: 44px;
    }
    .task-detail .answer-sheet .multiple-choice-wrap.inline-wrap .tips{
      min-width: 400px;
    }
    .task-detail {
      .answer-sheet-14_1,.answer-sheet-14_2,.answer-sheet-14_3{
        .multiple-choice-wrap .tips{
          display: block;
        }
      }
    }
  }
}