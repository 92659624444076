
:global{
  @swiperHeight: calc(100vw / 2560 * 1033);
  @topBarOriginHeight:114px;
  @titleBarOriginHeight:150px;
  @naviBarOriginHeight:185px;
  // 根据设备进行自适应185为底部导航 150为标题 114为顶部导航栏
  @totalToolHeight:calc(@topBarOriginHeight + @titleBarOriginHeight + @naviBarOriginHeight);
  @computedTopBarHeight:calc((100vh - (100vw / 2560 * 1033)) / (114 + 150 + 185) *  114);
  @computedtitleBarHeight:calc((100vh - 114px - (100vw / 2560 * 1033)) / (150 + 185) *  150);
  @computednaviBarHeight:calc((100vh - 114px - (100vw / 2560 * 1033)) / (150 + 185) *  185);
  @prowidth:2180px;
  .resetPassword{
    width: 100vw;
    // height: 100vw;
    position: relative;
    .resetPasswordWrapper{
      width: 1586px;
      height: 790px;
      background-color: #fff;
      margin: 0 auto;
      padding: 0 50px;
      .resetPasswordHeader{
        border-bottom: 1px solid #8F9DB1;
        p{
          text-align: left;
          height: 60px;
          line-height: 60px;
          margin-bottom: 0;
          span{
            color: #3A72FE;
            cursor: pointer;
          }
        }
      }
      .emailCheck{
        .stepsList{
          width: 790px;
          display: flex;
          justify-content: space-between;
          margin: 68px auto 0;
          .stepItem{
            width: 100px;
            height: 100px;
            .stepcircle{
              border: 1px solid #BDC0C2;
              border-radius: 50%;
              width: 65px;
              height: 65px;
              line-height: 65px;
              color: #BDC0C2;
              display: block;
              margin: 0 auto 10px; 
              font-size: 21px;
              position: relative;
              &.line{

                &::after{
                  width: 232px;
                  height: 1px;
                  border-bottom: 1px dashed #BDC0C2;
                  content: '';
                  display: block;
                  position: absolute;
                  left: 80px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
              &.active{
                border-color: #3A72FE;
                color: #3A72FE;
                &::after{
                  border-color: #3A72FE;
                }
              }
            }
            p{
              text-align: center;
            }
          }
        }
      }
      .InputWrapper{
        width: 565px;
        margin: 70px auto 0;
        .label{
          float: left;
          margin-bottom: 20px;
        }
        .inputItem{
          width: 100%;
          margin-bottom: 50px;
          display: flex;
          justify-content: space-between;
          position: relative;
          button{
            height: 100%;
            width: 230px;
            margin-left: 10px;
          }
          .inputErr{
            position: absolute;
            right: -480px;
            width: 480px;
            height: 52px;
            line-height: 52px;
            font-size: 16px;
            color: #FF4229;
            text-align: left;
            padding-left: 10px;
          }
        }
        .nextPage{
          margin-top: 20px;
          width: 565px;
          height: 53px;
          background-color: #3A72FE;
          color: #fff;
        }
        &.successWrapper{
          .successIcon{
            display: block;
            margin: 0 auto;
            width: 96px;
            height: 60px;
            margin-bottom: 30px;
            span svg{
              width: 96px;
              height: 60px;
              display: block;
              color: #3A72FE;
              fill: #3A72FE;
            }
          }
          .successText{
            color: #595F64;
            font-size: 24px;
          }
          .tips{
            font-size: 20px;
            color: #A7AAAC;
            margin-top: 30px;
          }
        }
      }
    }
  }

}