.App {
  text-align: center;
}
.adm-auto-center-content{
  word-break: break-all;
  user-select: all;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.returnButton span{
  margin-left: 20px;
  white-space: pre-wrap;
  cursor: pointer;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.task-block{
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
/* .task-block.task-list-page{
  background-image: url('./img/TaskList/background.jpg');
  background-size: 100% auto;
  background-position: 50% 50%;
} */

.task-block.question-page{
  background-image: url('./img/QuestionList/background.png');
}

@keyframes star-line-shine {
  0% {height: 500px; width: 500px;}
  23.5% {height: 100%; width: 100%;}
  100% {height: 100%; width: 100%;}
}

@keyframes success-txt-shine {
  0% {opacity: 0;}
  98% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes star-figure-shine {
  0% {opacity: 0;}
  23.5% {opacity: 0;}
  58.8% {opacity: 1;}
  82.4% {opacity: 1;}
  100% {opacity: 0.63;}
}

@keyframes star-shining {
  4.76%{background-image: url('img/QuestionList/starAnime/01.png');}
  9.52%{background-image: url('img/QuestionList/starAnime/02.png');}
  14.28%{background-image: url('img/QuestionList/starAnime/03.png');}
  19.04%{background-image: url('img/QuestionList/starAnime/04.png');}
  23.8%{background-image: url('img/QuestionList/starAnime/05.png');}
  28.56%{background-image: url('img/QuestionList/starAnime/06.png');}
  33.32%{background-image: url('img/QuestionList/starAnime/07.png');}
  38.08%{background-image: url('img/QuestionList/starAnime/08.png');}
  42.84%{background-image: url('img/QuestionList/starAnime/09.png');}
  47.6%{background-image: url('img/QuestionList/starAnime/10.png');}
  52.36%{background-image: url('img/QuestionList/starAnime/11.png');}
  57.12%{background-image: url('img/QuestionList/starAnime/12.png');}
  61.88%{background-image: url('img/QuestionList/starAnime/13.png');}
  66.64%{background-image: url('img/QuestionList/starAnime/14.png');}
  71.4%{background-image: url('img/QuestionList/starAnime/15.png');}
  76.16%{background-image: url('img/QuestionList/starAnime/16.png');}
  80.92%{background-image: url('img/QuestionList/starAnime/17.png');}
  85.68%{background-image: url('img/QuestionList/starAnime/18.png');}
  90.44%{background-image: url('img/QuestionList/starAnime/19.png');}
  94.12%{background-image: url('img/QuestionList/starAnime/20.png');}
  100%{background-image: url('img/QuestionList/starAnime/21.png');}
}



@keyframes rotateStar{
  0%{transform:rotate(0deg)}
  100%{transform:rotate(360deg)}
}

@keyframes opacity-ease-in{
  0%{opacity: 0;}
  100%{opacity: 0.63;}
}