body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
* 重置默认样式
* */
/* CSS Document */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
abbr,article,aside,audio,canvas,datalist,details,figure,footer,hgroup,header,mark,menu,meter,nav,output,progress,section,time,video{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
.article,aside,audio,canvas,datalist,details,figure,footer,hgroup,header,mark,menu,meter,nav,output,progress,section,time,video{
    display: block;
}
body {
    line-height: 1;
}
ol, ul ,li{
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before,blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
/* remember to define focus styles! */
:focus {
    outline: 0;}
/* remember to highlight inserts somehow! */
ins {
    text-decoration: none;
}
del {
    text-decoration: line-through;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
    border-collapse: collapse;
    border-spacing: 0;
}
legend{
    display:none;
}
img{ vertical-align: middle;}

input,button{
    overflow:visible;
    margin: 0;
    padding: 0;}
a{
    text-decoration: none;
}