// 132px ------------- 

:global{
  @gate__size:98px;
  @tag__size:110px;
  @tag__height:40px;
  @tag__container__padding_top:82px;
  .gate-H{
    background-image: url(../../img/TaskDetail/gate/H.svg);
  }
  .gate-X{
    background-image: url(../../img/TaskDetail/gate/X.svg);
  }
  .gate-Y{
    background-image: url(../../img/TaskDetail/gate/Y.svg);
  }
  .gate-Z{
    background-image: url(../../img/TaskDetail/gate/Z.svg);
  }
  .gate-I{
    background-image: url(../../img/TaskDetail/gate/I.svg);
  }
  .gate-CNOT{
    background-image: url(../../img/TaskDetail/gate/CNOT.svg);
  }
  .gate-ZCON{
    background-image: url(../../img/TaskDetail/gate/ZCON.svg);
  }
  .gate{
    width: @gate__size;
    height: @gate__size;
    border-radius: 10px;
    // color: #3082FC;
    font-weight: 400;
    font-size: 36px;
    line-height: @gate__size;
    text-align: center;
    // background-color: #E3F0FF;
    margin-right: 20px;
    background-size: cover;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat !important;
  }
  .circuitBoard{
    position: relative;
    margin-bottom: 50px;
    #mask{
      width: 100vw;
      height: 100vh;
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
    }

    
    .gate-contForSel{
      &::before{
        content: '';
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #C1C9D4;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      &::after{
        content: '';
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background-color: #C1C9D4;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .gateToolBars{
      width: 100%;
      display: flex;
      .gateContainer{
        margin: 0 auto;
      }
      
      .gate:last-child{
        margin-right: 0;
      }
      
    }
    .circuitBoardContainer{
      padding: @gate__size 0;
      width: 100%;
      min-height: 245px;
      position: relative;
      overflow:visible;
      user-select: none;
      .gate{
        transform: translate(-50%,-50%);
        margin-right: 0;
        z-index: 2;
        // -webkit-user-drag: auto !important;
        // cursor: move !important;
      }
      .gateWrap{
        position: absolute;
        z-index: 2;
      }
      .gate-tempNode{
        background-color: #f5f5f5;
        box-sizing: border-box;
      }
      .verticalLine{
        float: left;
        width: 2px;
        z-index: -1;
        background-color: #C1C9D4;
      }
      .contPlaceHolder{
        width: @gate__size;
        height: @gate__size;
        transform: translate(-50%,-50%);
        position: relative;
        &::after{
          content: '';
          width: 40px;
          height: 40px;
          border-radius: 20px;
          background-color: #C1C9D4;
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
        }
      }
      .tagContainer{
        position: absolute;
        left: 0;
        // height: 100%;
        width: 80px;
        padding-top: @tag__container__padding_top;
        .tag{
          // width: 60px;
          height: @tag__height;
          font-size: 40px;
          // margin-right: 20px;
          vertical-align: middle;
          position: relative;
          text-align: right;
          user-select:none;
          .tagDirac{
            position: absolute;
            font-size: 20px;
            color: #C1C9D4;
            top: 50%;
            transform: translateY(-50%);
            // margin-left: 20px;
            right: -40px;
            vertical-align: middle;
          }
        }
        .tagContent:not(:first-child){
          .tag {
            margin-top: @gate__size*2 - @tag__height;
          }
        }
      }
      .lineContainer{
        margin-left: @tag__size;
        padding: @gate__size 0;
        .lineContent{
          display: flex;
        }
        .line{
          height: 1px;
          vertical-align: middle;
          background-color: #C1C9D4;
          flex: 1;
          position: relative;
          transform: translateX(-2px);
          .circuitDot{
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            z-index: 1;
            display: block;
            top: 50%;
            background-color: #C1C9D4;
            left: 0;
            transform: translateY(-50%);
          }
        }
        
        
        .lineContent:first-child{
          .line{
            margin-top: 0px;
          }
        }
        .lineContent:not(:first-child){
          .line{
            margin-top: @gate__size*2;
          }
        }
        .lineContent:last-child{
          .line{
            margin-bottom: 0px;
          }
        }
      }
      .deletingFlag{
        position: absolute;
        bottom: 20px;
        width: 200px;
        height: 80px;
        border-radius: 20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #ED6161;
        line-height: 80px;
        color: #fff;
        font-size: 30px;
        text-align: center;
      }
      .draggingArea{
        position: absolute;
        border-radius: 10px;
        border: 2px dashed #C1C9D4;
        cursor: move !important;
      }
      .draggingArea.functionArea{
        border: none;
        background-color: rgba(0, 102, 204, 0.2);
        &::before{
          content: "f(x)";
          display: block;
          margin-top: -60px;
          font-size: 40px;
          text-align: center;
          font-family: 'Corbel Italic', 'Corbel';
          font-style: italic;
        }
      }
      .showFxInDraggingArea.draggingArea{
        &::after{
          content: "f(x)";
          display: block;
          margin-top: 50%;
          font-size: 40px;
          text-align: center;
          font-family: 'Corbel Italic', 'Corbel';
          font-style: italic;
          color: #0066CC;
        }
      }
      .arrContainer{
        .arrContent{
          width: 100%;
          height: 100%;
          position: relative;
          margin-left: 60px;
          margin-top: 30px;
          i{
            font-size: 100px;
            display: block;
            transform: rotate(180deg);
            color: #C1C9D4;
          }
          .arrTagContainer{
            margin-top: 10px;
            p{
              text-align: center;
              font-size: 40px;
              .minfont{
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .errFlag .draggingArea.draggingBox{
    border-color: #E71F0F;
  }
}