:global{

  @star-size: 106px;

    
    .star {
      position: absolute;
      width: @star-size;
      height: @star-size;
      background-position: center;
      background-size: cover;
      display: none;
      z-index: 4;
      &.shining {
        display: block;
        // animation: star-shining 2.1s linear infinite;
        // background-image: url("img/QuestionList/star-shining.gif");
        background-image: url("img/QuestionList/animation.png"); // 该图为APNG
      }
      &.active {
        display: block;
        background-image: url("img/QuestionList/starNode.png");
      }
      &.course-1-star {
        &#course-1-star-1 {
          left: 546px;
          top: 778px;
        }
        &#course-1-star-2 {
          left: 574px;
          top: 528px;
        }
        &#course-1-star-3 {
          left: 984px;
          top: 442px;
        }
        &#course-1-star-4 {
          left: 546px;
          top: 1166px;
        }
      }
  
      &.course-2-star {
        &#course-2-star-1 {
          left: 234px;
          top: 1054px;
        }
      }

      &.course-3-star {
        &#course-3-star-1 {
          left: 766px;
          top: 665px;
        }
        &#course-3-star-2 {
          left: 960px;
          top: 710px;
        }
        &#course-3-star-3 {
          left: 1057px;
          top: 774px;
        }
      }

      &.course-4-star {
        &#course-4-star-1 {
          left: 190px;
          top: 406px;
        }
        &#course-4-star-2 {
          left: 612px;
          top: 622px;
        }
        &#course-4-star-3 {
          left: 604px;
          top: 742px;
        }
        &#course-4-star-4 {
          left: 894px;
          top: 812px;
        }
        &#course-4-star-5 {
          left: 1200px;
          top: 854px;
        }
      }

      &.course-5-star {
        &#course-5-star-1 {
          left: 489px;
          top: 528px;
        }
        &#course-5-star-2 {
          left: 732px;
          top: 519px;
        }
        &#course-5-star-3 {
          left: 514px;
          top: 738px;
        }
        &#course-5-star-4 {
          left: 847px;
          top: 758px;
        }
        &#course-5-star-5 {
          left: 636px;
          top: 1156px;
        }
        &#course-5-star-6 {
          left: 1048px;
          top: 945px;
        }
      }

      &.course-6-star {
        &#course-6-star-1 {
          left: 336px;
          top: 673px;
        }
        &#course-6-star-2 {
          left: 662px;
          top: 688px;
        }
        &#course-6-star-3 {
          left: 710px;
          top: 878px;
        }
        &#course-6-star-4 {
          left: 874px;
          top: 806px;
        }
        &#course-6-star-5 {
          left: 848px;
          top: 639px;
        }
        &#course-6-star-6 {
          left: 1170px;
          top: 500px;
        }
      }

      &.course-7-star {
        &#course-7-star-1 {
          left: 956px;
          top: 720px;
        }
      }

      &.course-8-star {
        &#course-8-star-1 {
          left: 638px;
          top: 670px;
        }
      }

      &.course-9-star {
        &#course-9-star-1 {
          left: 807px;
          top: 328px;
        }
      }

      &.course-10-star {
        &#course-10-star-1 {
          left: 854px;
          top: 608px;
        }
      }

      &.course-11-star {
        &#course-11-star-1 {
          left: 749px;
          top: 625px;
        }
        &#course-11-star-2 {
          left: 919px;
          top: 550px;
        }
        &#course-11-star-3 {
          left: 739px;
          top: 750px;
        }
        &#course-11-star-4 {
          left: 1007px;
          top: 806px;
        }
        &#course-11-star-5 {
          left: 470px;
          top: 676px;
        }
        &#course-11-star-6 {
          left: 586px;
          top: 1196px;
        }
      }
      &.course-10-star {
        &#course-10-star-1 {
          left: 854px;
          top: 608px;
        }
      }
      &.course-12-star {
        &#course-12-star-1 {
          left: 1084px;
          top: 608px;
        }
        &#course-12-star-2 {
          left: 404px;
          top: 644px;
        }
      }
      &.course-13-star {
        &#course-13-star-1 {
          left: 1084px;
          top: 1008px;
        }
        &#course-13-star-2 {
          left: 678px;
          top: 460px;
        }
        &#course-13-star-3 {
          left: 474px;
          top: 580px;
        }
      }
      &.course-14-star {
        &#course-14-star-1 {
          left: 934px;
          top: 422px;
        }
        &#course-14-star-2 {
          left: 690px;
          top: 704px;
        }
        &#course-14-star-3 {
          left: 896px;
          top: 992px;
        }
      }
      &.course-15-star {
        &#course-15-star-1 {
          left: 724px;
          top: 410px;
        }
      }
      &.course-16-star {
        &#course-16-star-1 {
          left: 800px;
          top: 760px;
        }
      }
    }
}