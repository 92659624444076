@font-face {
  font-family: "iconfont"; /* Project id 3080377 */
  src: url('iconfont.woff2?t=1649902593737') format('woff2'),
       url('iconfont.woff?t=1649902593737') format('woff'),
       url('iconfont.ttf?t=1649902593737') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-starname-orion:before {
  content: "\e602";
}

.icon-starname-pisces:before {
  content: "\e603";
}

.icon-starname-taurus:before {
  content: "\e604";
}

.icon-starname-aquarius:before {
  content: "\e605";
}

.icon-starname-triangulum:before {
  content: "\e606";
}

.icon-starname-virgo:before {
  content: "\e607";
}

.icon-starname-aeies:before {
  content: "\e608";
}

.icon-starname-sagittarii:before {
  content: "\e609";
}

.icon-starname-scorpio:before {
  content: "\e60a";
}

.icon-starname-hercules:before {
  content: "\e60b";
}

.icon-star-for-constellation-camp:before {
  content: "\e60c";
}

.icon-sheng:before {
  content: "\e615";
}

.icon-arrow:before {
  content: "\e601";
}

.icon-select:before {
  content: "\e67e";
}

.icon-arrow-left-bold:before {
  content: "\e685";
}

.icon-arrow-right-bold:before {
  content: "\e687";
}

.icon-icon-left-arr:before {
  content: "\e600";
}

