:global{

  @star-txt-num-color: #1C2343;
  @star-txt-en-normal-color: #9A7AFF;
  @star-txt-num-background-normal-color: #B97AFF;
  @star-txt-cn-normal-color: #9A7AFF;
  @star-txt-en-active-color: #FFEAB2;
  @star-txt-num-background-active-color: #FCDEA5;
  @star-txt-cn-active-color: #FFD554;
  @star-compass-radius: 590px;

  .basic-bg {
    will-change: transform;
    height: 100%;
    width: 100%;
    position: absolute;
    // background-image: url('img/TaskList/background.jpg');
    background-position: center center;
    background-size: 100% auto;
    background-color: transparent;
  }
  .app.pro{
    .task-list.training {
      height: 100%;
      margin-top: 70px;
      transform: scale(0.75);
      border-radius: 10px;
      overflow: hidden;
    }
  }
  .task-list-modal {
    .adm-modal-wrap,.adm-center-popup-wrap {
      width: 1140px;
      // height: 500px;
      .adm-modal-body {
        padding: 90px 106px 92px 246px;
        .adm-modal-content{
          padding-bottom: 0;
          overflow-y: visible;
          overflow-x: visible;
        }
        .star-icon-wrap {
          width: 356px;
          height: 376px;
          top: 0;
          left: 0;
          transform: translateY(-50%) translateX(-27%);
          position: absolute;
          background-image: url('img/TaskList/modal-star.png'); 
          background-size: cover;
          background-position: center;
        }
        .txt-wrap {
          font-size: 42px;
          text-align: center;
          color: #595F64;
          .cheerup {
            margin-top: 42px;
            line-height: 42px;
            color:#FC9A00;
            font-weight: bold;
            transform: translateX(-40px);
          }
        }
      }
      .adm-modal-footer {
        margin-top: 50px;
        .adm-button-primary {
          width: 434px;
          height: 82px;
          margin: 0 auto;
          transform: translateX(-60px);
          line-height: 82px;
          font-size: 36px;
          background-color: #575ffc;
          color: #FFFFFF;
          &:active {
            background-color: #4c55fa;
          }
        }
      }
    }
  }

  .star-compass-description {
    position: absolute;
    top: 168px;
    left: 360px;
    .title {
      font-size: 42px;
      color: #FFFFFF;
    }
    .tips {
      font-size: 32px;
      color: #FCE99B;
      margin-top: 28px;
    }
  }
  .enApp .star-compass-description{
    left: 0;
  }

  .txt-wrapper {
    width: @star-compass-radius*2;
    height: @star-compass-radius*2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 6;

    .star-txt {
      position: absolute;
      text-align: center;
      .en-wrapper {
        position: absolute;
        width: 226px;
        height: @star-compass-radius;
        transform-origin: 0% 100%;
        .en {
          font-size: 34px;
          width: 100%;
          height: 100%; 
          fill: @star-txt-en-normal-color; 
          letter-spacing: 2px;
          background-color: transparent;
        }
        
      }
      .num-cn-wrapper {
        .num-wrapper {
          .num {
            font-size: 28px;
            width: 42px;
            height: 42px;
            line-height: 42px;
            margin: 0 auto;
            color: @star-txt-num-color;
            background-color: @star-txt-num-background-normal-color;
            border-radius: 50%;
          }
          .num.icon-star-for-constellation-camp{
            color:  @star-txt-num-background-normal-color;
            background-color: unset;
            font-size: 80px;
            width: 100px;
            height: 100px;
            position: relative;
            &::before{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
            }
            span{
              width: 100%;
              height: 100%;
              line-height: 100px;
              display: block;
              text-align: center;
              position: absolute;
              font-size: 30px;
              z-index: 11;
              color: @star-txt-num-color;
            }
          }
        }
        .cn {
          margin-top: 14px;
          font-size: 40px;
          color: @star-txt-cn-normal-color;
        }
      }

      &.active-txt {
        .en-wrapper .en {
          fill: @star-txt-en-active-color;
        }
        .num-cn-wrapper {
          .num-wrapper .num {
            background-color: @star-txt-num-background-active-color;
          }
          .num.icon-star-for-constellation-camp{
            color:  @star-txt-en-active-color;
            background-color: unset;
            font-size: 80px;
            span{
              font-size: 30px;
            }
          }
          .cn {
            color: @star-txt-cn-active-color;
          }
        }
      }
      
      .inner-style(@simple-txt-x, @simple-txt-y, @curve-txt-rotation){
        .num-cn-wrapper {
          transform: translateX(@simple-txt-x) translateY(@simple-txt-y);
        }
        .en-wrapper {
          transform: translateX(591px) rotate(@curve-txt-rotation);
        }
      }
      &#txt-1 {
        .inner-style(450px, 116px, -20.2deg)
      }
      &#txt-2 {
        .inner-style(304px, 180px, -40.6deg)
      }
      &#txt-3 {
        .inner-style(194px, 304px, -62.2deg)
      }
      &#txt-4 {
        .inner-style(128px, 464px, -86.2deg)
      }
      &#txt-5 {
        .inner-style(128px, 620px, -108.2deg)
      }
      &#txt-6 {
        .inner-style(188px, 756px, -130.6deg)
      }
      &#txt-7 {
        .inner-style(304px, 866px, -150.6deg)
      }
      &#txt-8 {
        .inner-style(450px, 947px, -175deg)
      }
      &#txt-9 {
        .inner-style(610px, 947px, 163.8deg)
      }
      &#txt-10 {
        .inner-style(760px, 866px, 138.4deg);
        .num-wrapper .num {
          padding-top: 2px;
          padding-right: 3px;
        }
      }
      &#txt-11 {
        .inner-style(876px, 752px, 114.2deg)
      }
      &#txt-12 {
        .inner-style(936px, 616px, 90deg);
        .en-wrapper .en {
          font-size: 32px;
          letter-spacing: 1px;
        }
        .num-wrapper .num {
          padding-top: 1px;
          padding-right: 3px;
        }
      }
      &#txt-13 {
        .inner-style(936px, 462px, 67.8deg);
        .en-wrapper .en {
          font-size: 32px;
        }
        .num-wrapper .num {
          padding-top: 1px;
          padding-right: 3px;
        }
      }
      &#txt-14 {
        .inner-style(874px, 300px, 50.4deg);
        .num-wrapper .num {
          padding-top: 1px;
          padding-right: 3px;
        }
      }
      &#txt-15 {
        .inner-style(760px, 180px, 25deg);
        .num-wrapper .num {
          padding-top: 1px;
          padding-right: 3px;
        }
      }
      &#txt-16 {
        .inner-style(612px, 114px, 5deg);
        .num-wrapper .num {
          padding-top: 2px;
          padding-right: 3px;
        }
      }
    }
  }

  @media screen and (max-width: 2000px) {
    .txt-wrapper .star-txt {
      .en-wrapper .en {
        font-size: 44px;
      }
      &#txt-12 .en-wrapper .en {
        font-size: 44px;
      }
      &#txt-13 .en-wrapper .en {
        font-size: 44px;
      }
    }
  }

  @media screen and (max-width: 1560px) {
    .txt-wrapper .star-txt {
      .en-wrapper .en {
        font-size: 58px;
      }
      &#txt-12 .en-wrapper .en {
          font-size: 56px;
      }
      &#txt-13 .en-wrapper .en {
        font-size: 56px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .txt-wrapper .star-txt {
      .en-wrapper .en {
        font-size: 85px;
      }
      &#txt-12 .en-wrapper .en {
          font-size: 84px;
      }
      &#txt-13 .en-wrapper .en {
        font-size: 84px;
      }
    }
  }

  .center-wrapper {
    position: absolute;
    width: @star-compass-radius*2;
    height: @star-compass-radius*2;
    overflow: hidden;
    margin-left: 1px;
    margin-top: -1px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    .center {
      // background-image: url('img/TaskList/center.png'); 
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
      &.rotating {
        animation: rotateStar 20s linear 1;
      }
    }
   
  }

  .circleBlock {
    width: 1182px;
    height: 1182px;
    // border: 1px solid red;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    overflow: hidden;
    border-radius: 50%;
    z-index: 99;
    opacity: 0;
  }
  
  .circle {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    transform-origin: 0% 100%;
    // overflow: hidden;
  }

  .circle1 {
    background: #7B72A9;
    transform: rotate(-22.5deg) skewY(-67.5deg);
  }
  
  .circle2 {
    background: #8E84B8;
    transform: rotate(-45deg) skewY(-67.5deg);
  }
  
  .circle3 {
    background: #A299C4;
    transform: rotate(-67.5deg) skewY(-67.5deg);
  }
  
  .circle4 {
    background: #8E84B8;
    transform: rotate(-90deg) skewY(-67.5deg);
  }
  
  .circle5 {
    background: #7970A6;
    transform: rotate(-112.5deg) skewY(-67.5deg);
  }
  
  .circle6 {
    background: #8E84B8;
    transform: rotate(-135deg) skewY(-67.5deg);
  }
  
  .circle7 {
    background: #7970A6;
    transform: rotate(-157.5deg) skewY(-67.5deg);
  }

  .circle8 {
    background: #8E84B8;
    transform: rotate(-180deg) skewY(-67.5deg);
  }

  .circle9 {
    background: #7970A6;
    transform: rotate(-202.5deg) skewY(-67.5deg);
  }

  .circle10 {
    background: #8E84B8;
    transform: rotate(-225deg) skewY(-67.5deg);
  }

  .circle11 {
    background: #7970A6;
    transform: rotate(-247.5deg) skewY(-67.5deg);
  }

  .circle12 {
    background: #8E84B8;
    transform: rotate(-270deg) skewY(-67.5deg);
  }
  
  .circle13 {
    background: #7970A6;
    transform: rotate(-292.5deg) skewY(-67.5deg);
  }

  .circle14 {
    background: #8E84B8;
    transform: rotate(-315deg) skewY(-67.5deg);
  }

  .circle15 {
    background: #7970A6;
    transform: rotate(-337.5deg) skewY(-67.5deg);
  }

  .circle16 {
    background: #8E84B8;
    transform: rotate(0deg) skewY(-67.5deg);
  }
  .enApp {
    
    .txt-wrapper .star-txt .num-cn-wrapper .cn{
      // opacity: 0;
    }
    .txt-wrapper .star-txt{
      .inner-style(@simple-txt-x, @simple-txt-y, @curve-txt-rotation){
        .num-cn-wrapper {
          transform: translateX(@simple-txt-x) translateY(@simple-txt-y);
        }
        .en-wrapper {
          transform: translateX(591px) rotate(@curve-txt-rotation);
        }
      }
      &#txt-1 {
        .inner-style(460px, 116px, -20.2deg);
      }
      &#txt-6 {
        .inner-style(188px, 766px, -130.6deg)
      }
      &#txt-7 {
        .inner-style(304px, 886px, -150.6deg)
      }
      &#txt-8 {
        .inner-style(460px, 953px, -175deg)
      }
      &#txt-9 {
        .inner-style(620px, 953px, 163.8deg)
      }
      &#txt-10 {
        .inner-style(770px, 886px, 138.4deg);
      }
      &#txt-11 {
        .inner-style(886px, 766px, 114.2deg)
      }
      &#txt-12 {
        .inner-style(946px, 616px, 90deg);
      }
      &#txt-13 {
        .inner-style(946px, 462px, 67.8deg);
      }
      &#txt-14 {
        .inner-style(884px, 310px, 50.4deg);
      }
      &#txt-15 {
        .inner-style(770px, 180px, 25deg);
      }
      &#txt-16 {
        .inner-style(616px, 114px, 5deg);
      }
    }
    .task-list-modal .adm-modal-wrap{
      width: 1360px;
    }
  }
}