:global{
  .edu-loading {
    width: 2150px;
    // padding: 106px 0 40px 0;
    height: calc(100vh - 106px - 40px);
    box-sizing: border-box;
    position: fixed;
    top: 106px;
    border-radius: 20px;
    overflow: hidden;
    left: 366px;
    z-index: 9;
    display: none;
    .edu-loading-wrap{
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
    svg {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 140px;
      height: 140px;
      animation: App-logo-spin 4s linear infinite;
    }
  }
  .showLoading .edu-loading{
    display: block;
  }
}