
:global{
  @swiperHeight: calc(100vw / 2560 * 1033);
  @topBarOriginHeight:114px;
  @titleBarOriginHeight:150px;
  @naviBarOriginHeight:185px;
  // 根据设备进行自适应185为底部导航 150为标题 114为顶部导航栏
  @totalToolHeight:calc(@topBarOriginHeight + @titleBarOriginHeight + @naviBarOriginHeight);
  @computedTopBarHeight:calc((100vh - (100vw / 2560 * 1033)) / (114 + 150 + 185) *  114);
  @computedtitleBarHeight:calc((100vh - 114px - (100vw / 2560 * 1033)) / (150 + 185) *  150);
  @computednaviBarHeight:calc((100vh - 114px - (100vw / 2560 * 1033)) / (150 + 185) *  185);
  @prowidth:2180px;
  .userSettingBlock{
    width: 980px;
    height: 937px;
    background-color: #fff;
    border-radius: 5px;
    padding: 73px 120px 120px 120px;
    box-sizing: border-box;
    margin: 0 auto;
    .bigTit {
      font-size: 29px;
      color: #2441E0;
      text-align: left;
      &.mt50{
        margin-top: 50px;
      }
    }
    .label{
      font-size: 19px;
      color: #595F64;
      text-align: left;
      margin-top: 35px;
    }
    .inputItem{
      height: 90px;
      line-height: 90px;
      border-bottom: 1px solid #595F64;
      position: relative;
      p{
        font-size: 24px;
        color: #595F64;
        text-align: left;
      }
      .editIcon{
        width: 25px;
        height: 32px;
        display: inline-block;
        position: absolute;
        right: 0;
        bottom: 33px;
        color: #0078FF;
        fill: #0078FF;
        cursor: pointer;
        svg{
          width: 25px;
          height: 32px;
        }
      }
      .inputErr{
        position: absolute;
        right: 0;
        bottom: -30px;
        margin-bottom: 0;
        line-height: 30px;
        color: #FF4229;
      }
    }
    .changePasswordModal{
      width: 900px !important;
      height: 910px;
      .ant-modal-header{
        height: 80px;
        width: 100%;
        background-color: #0078FF;
        text-align: left;
        padding-top: 27px;
        .ant-modal-title{
          color: #fff;
          font-size: 26px;
        }
      }
      .ant-modal-body{
        height: 700px;
        width: 100%;
        background-color: #F6F9FC;
        .tips{
          width: 743px;
          height: auto;
          background-color: #F0F0F0;
          color: #595F64;
          font-size: 19px;
          margin: 0 auto;
          padding-left: 72px;
          box-sizing: border-box;
          position: relative;
          text-align: left;
          padding-top: 26px;
          padding-bottom: 26px;
          padding-right: 20px;
          .iconSvg{
            width: 24px;
            height: 24px;
            position: absolute;
            // top: 20px;
            top: 50%;
            transform: translateY(-50%);
            left: 30px;
            svg{
              fill: #0078FF;
              width: 100%;
              height: 100%;
            }
          }
          p{
            margin-bottom: 0;
          }
        }
        .inputItem{
          border-bottom: none;
          svg{
            width: 26px;
            height: auto;
            fill: #BDC0C2;
            color: #BDC0C2;
          }
        }
      }
      .ant-modal-footer{
        height: 126px;
        background-color: #F6F9FC;
        border-top: none;
        text-align: center;
        button{
          width: 236px;
          height: 53px;
          &.ant-btn-primary{
            margin-left: 106px;
          }
        }
      }
      .changePasswordModalBody{
        width: 743px;
        margin: 0 auto;
        .ant-input-affix-wrapper{
          height: 54px;
          line-height: 54px;
        }
        input{
          font-size: 19px;
          box-sizing: border-box;
        }
      }
      &.changeName {
        .ant-modal-body{
          height: 400px;
          .tips{
            box-sizing: content-box;
            padding-top: 0;
            height: auto;
            padding-bottom: 26px;
            line-height: 30px;
            padding-top: 26px;
            p{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .userRegisterPopoverLayClass{
    height: 80px;
    width: 445px;
    font-size: 16px;
    color: #595F64;
    .ant-popover-inner-content{
      background-color: #EAEAEA;
      padding: 15px 10px;
      
    }
    .ant-popover-arrow{
      display: none;
    }
  }
}