:global{
  .padding-wrap{
    padding-top: 174px;
    box-sizing: border-box;
  }
  .pro-nav-tit{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 96px;
    // position: relative;
    background-color: unset;
    margin-top: 80px;
    z-index: 9;
    .nav-linker{
      height: 96px;
      line-height: 96px;
      font-size: 25px;
      text-align: center;
      .linker{
        height: 56px;
        line-height: 56px;
        padding: 0 54px;
        border-radius: 28px;
        display: inline-block;
        margin-right: 0;
        color: #fff;
        &:not(:last-child){
          margin-right: 60px;
        }
        &:hover{
          color: #22DEFF;
          cursor: pointer;
        }
      }
      .linker.active{
        background-color: #007CFF;
        color: #fff;
      }
    }
  }
}