:global{
  .Pact-block{
    height: 100%;
    overflow-y: scroll;
  }
  .term {
    padding-top: 40px;
    width: 100vw;
    overflow: hidden;
    .nav-tit .backTo{
      span{
        margin-left: 20px;
        font-size: 36px;
      }
    }
    *{
      font-size: 32px;
      box-sizing: border-box;
    }
    margin-top: 80px;
    .content{
      width: 1650px;
      margin: 0 auto;
      
    }
    .prodSpec {
      
      border-bottom: 2px solid #e5e5e5;

      .subNav-wrapper {
        border: none;
        
      }

      .subNav {
        margin: 0;

        .subNav-window {
          margin: 0;

          ul {
            display: block;

            li:last-child {
              a {
                cursor: auto;

                &:hover {
                  color: #2c3e50;
                }
              }
            }
          }
        }
      }
    }
    .pact{
      padding: 100px 0;
      p{
        text-align: justify;
        a{
          line-height: 28px;
          display: inline-block;
          text-indent: 0;
        }
      }
      width: 1650px;
      margin: 0 auto;
      .bigTitle{
        line-height: 120px;
        height: 120px;
        font-size: 42px;
        color: #303030;
        border-bottom: 1px solid #BBBBBD;
        margin-bottom: 38px;
      }
      .mt50{
        margin-top: 40px;
      }
      p{
        color: #575859;
        margin: 0;
        line-height: 48px;
      }
      .href{
        color: #0066CC;
        background: none;
        border: none;
      }
      .textIndent2{
        text-indent: 2em;
      }
      .subTitle{
        font-size: 32px;
        margin-bottom: 24px;
        font-weight: bold;
        color: #303030;
        text-align: left;
      }
      .subList{
        padding-left: 0;
        position: relative;
        text-indent: 2em;
        &::after{
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: #575859;
          position: absolute;
          left: 3px;
          top: 23px;
        }
      }

      .textIndent0{
        text-indent: 0;
      }
    }
    .policyTable{
      color: #575859;
      margin: 0 auto;
      thead{
        background-color: #fff;
      }
      tr{
        td,th{
          padding-left: 10px;
          min-width: 200px;
          text-align: left;
          padding: 10px 20px;
        }
        th{
          line-height: 80px;
          box-sizing: border-box;
        }
        th:not(:last-child),td:not(:last-child) {
          border-bottom: 1px solid #2c3e50;
          border-right: 1px solid #2c3e50;
        }
        th:last-child,td:last-child {
          border-bottom: 1px solid #2c3e50;
        }
      }
    }
    .policyTable.cookieTable{
      border: 1px solid #2c3e50;
      border-bottom: none;
      tr{
        th{
          height: 90px;
          text-align: center;
        }
        td:nth-child(1),th:nth-child(1){
          min-width: 208px;
          max-width: 208px;
          text-align: center;
          
        }
        td:nth-child(2),th:nth-child(2){
          min-width: 500px;
          max-width: 500px;
        }
      }
    }
  }
.enApp{
  .term {
    .pact{
      p{
        text-align: left;
      }
      .textIndent2{
        text-indent: 0;
      }
      .subList{
        &::after{
          left: 1em;
        }
      }
    }
  }
}
}