

:global{
  @swiperHeight: calc(100vw / 2560 * 1033);
  @topBarOriginHeight:114px;
  @titleBarOriginHeight:150px;
  @naviBarOriginHeight:185px;
  // 根据设备进行自适应185为底部导航 150为标题 114为顶部导航栏
  @totalToolHeight:calc(@topBarOriginHeight + @titleBarOriginHeight + @naviBarOriginHeight);
  @computedTopBarHeight:calc((100vh - (100vw / 2560 * 1033)) / (114 + 150 + 185) *  114);
  @computedtitleBarHeight:calc((100vh - 114px - (100vw / 2560 * 1033)) / (150 + 185) *  150);
  @computednaviBarHeight:calc((100vh - 114px - (100vw / 2560 * 1033)) / (150 + 185) *  185);
  @prowidth:2180px;

  .webHeader{
    height: 60px;
    background-color: #fff;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    .headerContent{
      position: relative;
      width: 100%;
      height: 100%;
      padding-left: 34px;
      padding-right: 36px;
      .pt11{
        padding-top: 11px;
        display: inline-block;
      }
      img{
        width: 100px;
        height: 34px;
      }
      .eduItem{
        display: inline-block;
        padding-left: 20px;
        border-left: 2px solid #D1D3D9;
        text-align: center;
        margin-left: 20px;
        color: #192B43 ;
        cursor: pointer;
        &:hover{
          color: #136CFB;
        }
      }
      .langSetting.userSetting{
        float: right;
        margin-right: 80px;
        .label {
          height: 100%;
          .arrDown{
            position: absolute;
            right: 30px;
          }
          .labelText{
            vertical-align: top;
          }
        }
      }
      .userSetting{
        float: right;
        vertical-align: top;
        .label{
          position: relative;
          height: 100%;
          .labelText{
            font-size: 19px;
            color: #192B43;
            display: inline-block;
            width: 210px;
            padding-left: 40px;
            padding-right: 20px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
            position: relative;
          }
          .userSvg{
            position: absolute;
            left: 0px;
            width: 34px;
            height: 34px;
            color: #136CFB;
            fill: #136CFB;
            top: 50%;
            transform: translateY(-50%);
          }
          .arrDown{
            position: absolute;
            fill: #192B43;
            color: #192B43;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            svg{
              transition: all .3s;
            }
          }
          .langSvg{
            position: absolute;
            width: 28px;
            height: 28px;
            left: 40px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .dropDown{
          background-color: #fff;
          border-radius: 5px;
          overflow: hidden;
          box-shadow: #192B43;
          .dropDownItem{
            width: 100%;
            height: 0;
            text-align: center;
            line-height: 60px;
            font-size: 19px;
            color: #192B43;
            transition: all .3s;
            overflow: hidden;
            &:hover{
              background-color: #DCE6FF;
              color: #136CFB;
            }
          }
        }
        &:hover{
          .dropDownItem{
            height: 60px;
            cursor: pointer;
          }
          .label .arrDown{
            svg{
              transform: rotate(180deg);
            }
          }
        }
      }
      .langSetting{
        .label{
          font-size: 19px;
          line-height: 60px;
          text-align: center;
        }
      }
    }
  }
  
}
