:global{
  .StuRegister{
    padding: 50px;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    .importBtn{
      padding: 0 30px 0 66px;
      background-color: #3A72FE;
      font-size: 22px;
      height: 52px;
      line-height: 52px;
      border-radius: 5px;
      float: left;
      margin-bottom: 20px;
      .anticon + span{
        margin-left: 20px;
      }
      img{
        width: 26px;
        height: 27px;
        display: inline-block;
        margin-right: 20px;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(0,-50%);
      }
    }
    .freshBtn{
      font-size: 22px;
      float: right;
      color: #3A72FE;
    }
    .searchInput{
      width: 450px;
      height: 52px;
      color: #A5AAC1;
      margin-left: 25px;
      position: relative;
      .ant-input-prefix{
        align-items: start;
        vertical-align: top;
        width: 17PX;
        height: 22PX;
        display: inline-block;
        // padding-left: 40px;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        span{
          display: inline-block;
          width: 100%;
          height: 100%;
          vertical-align: top;
          svg{
            width: 100%;
            height: 100%;
          }
        }
      }
      input{
        margin-left: 30px;
        font-size: 21px;
      }
    }
    .tableList{
      margin-top: 30px;
      // height: 400px;
    }
    .ant-table{
      font-size: 22px;
      table {
        width: auto;
      }
      .ant-table-thead > tr > th{
        background-color: #E8E8F6;
      }
      th.ant-table-cell-ellipsis {
        pointer-events: none; // 阻止头部的溢出导致title触发
      }
      .ant-table-thead tr {
        .ant-table-cell-scrollbar{
          box-shadow: none;
        }
        .ant-table-cell{
          padding: 20px 0 20px 0;
          &.ant-table-cell-ellipsis:nth-child(1){
            padding-left: 20px;
          }
          &::before{
            display: none;
          }
        }
        .ant-table-column-sorters{
          justify-content: start;
          .ant-table-column-title{
            flex: none;
            margin-right: 10px;
          }
        }
      }
      .freshBtn.del{
        color: #F8385C;
        padding: 0;
      }
      .ant-table-row{
        height: 92px;
        .ant-table-cell{
          padding: 20px 0 20px 0;
          &.ant-table-cell-ellipsis:nth-child(1){
            padding-left: 20px;
          }
          align-items: center;
          vertical-align: middle;
          white-space: nowrap;
          // overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    
    .noData{
      margin-top: 350px;
      font-size: 19px;
      text-align: center;
      p{
        margin-top: 40px;
        color: #595F64;
      }
    }
    .ant-pagination{
      width: 100%;
      font-size: 20px;
      margin-top: 28px;
      display: flex;
      justify-content: flex-end;
      position: relative;
      .ant-pagination-disabled{
        svg{
          fill: #D3D3D3 !important;
        }
      }
      .ant-pagination-total-text{
        position: absolute;
        left: 0;
        color: #A5AAC1;
      }
      .ant-select-selection-search,.ant-select-selection-item{
        font-size: 20px;
      }
      .ant-pagination-item{
        display: none;
      }
      .ant-pagination-prev{
        margin-right: 0;
        margin-left: 10px;
        border: 1px solid #BAC3CF;
        svg{
          height: 50%;
          fill: #3A72FE;
        }
      }
      .ant-pagination-next{
        margin-right: 10px;
        order: 5;
        border: 1px solid #BAC3CF;
        svg{
          height: 50%;
          fill: #3A72FE;
        }
      }
      .ant-pagination-next.end-page{
        margin-right: 0;
      }
      .showTotalPage{
        height: 100%;
        vertical-align: top;
        background-color: #fff;
        border: 1px solid #D0D0E0;
        min-width: 70px;
        margin-right: 10px;
        line-height: 30px;
      }
      .ant-pagination-options{
        margin-left: 0;
        .ant-pagination-options-quick-jumper{
          margin-left: 0;
          float: left;
          input{
            margin-right: 0;
            margin-left: 10px;
            width: 160px;
            text-align: center;
            vertical-align: top;
            font-size: 20px;
          }
        }
      }
    }
  }
  .eduModal{
    width: 900px !important;
    height: 590px;
    .ant-modal-content{
      height: 100%;
      .ant-modal-header{
        height: 80px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #3A72FE;
        .ant-modal-title{
          line-height: 80px;
          font-size: 26px;
          color: #fff;
        }
      }
      .ant-modal-close-x{
        width: 80px;
        height: 80px;
        span{
          vertical-align: middle;
          line-height: 80px;
          color: #fff;
          font-size: 30px;
        }
      }
      .ant-modal-body{
        font-size: 20px;
        padding-bottom: 0;
        .tips{
          width: 740px;
          // height: 86px;
          padding: 20px 20px 20px 60px;
          line-height: 40px;
          background-color: #F0F0F0;
          margin: 40px auto;
          position: relative;
          .ant-btn-link{
            padding-right: 0;
          }
          .freshBtn{
            color: #3A72FE;
            svg{
              width: 18px;
              height: 18px;
              margin-right: 10px;
              margin-top: 8px;
              fill: #3A72FE;
              vertical-align: top;
            }
            span{
              text-decoration: underline;
            }
          }
          .anticon-info-circle{
            color: #0078FF;
            position: absolute;
            left: 20px;
            top: 30px;
          }
        }
        .ant-btn{
          font-size: 20px;
        }
        .chooseFile{
          margin-left: 60px;
          width: 740px;
          white-space: nowrap;
          color: #595F64;
          
          position: relative;
          .labelInFile{
            width: 110px;
            display: inline-block;
          }
          .fileName{
            display: inline-block;
            width: 590px;
            line-height: 30px;
            vertical-align: middle;
            border-bottom: 1px solid #D0D0E0;
            overflow: hidden;
            text-overflow: ellipsis;
            .anticon-close{
              fill: #D0D0E0;
              position: absolute;
              right: 4px;
              top: 50%;
              transform: translateY(-50%);
              width: 24px;
              height: 24px;
            }
          }
          .ant-btn{
            border-color: #3A72FE;
            color: #3A72FE;
            height: 54px;
            line-height: 44px;
            width: 238px;
            vertical-align: middle;
            // margin-left: 10px;
            margin-right: 10px;
          }
        }
        .chooseFile.txtCss{
          padding-top: 9px;
          height: 54px;
        }
        .chooseFile.enChoose .labelInFile{
          width: 150px;
        }
        .fileTips{
          color: #A5AAC1;
          font-size: 21px;
          padding-left: 170px;
          margin-top: 10px;
        }
        .fileTips.enFileTips{
          padding-left: 210px;
        }
        .errorType{
          color: #FF4229;
        }
      }
      .ant-modal-footer{
        text-align: center;
        border-top: none;
        button{
          width: 236px;
          height: 54px;
          font-size: 23px;
        }
        button:nth-child(1){
          margin-right: 106px;
          margin-top: 40px;
        }
      }
    }
    .ant-btn-default{
      color: #3A72FE;
      border-color: #BAC3CF;
    }
    .ant-btn-primary{
      background-color: #3A72FE;
    }
  }
  .eduModal.delModal{
    height: 430px;
    padding-bottom: 0;
    .ant-modal-content .ant-modal-body {
      height: 230px;
      box-sizing: border-box;
      .tips{
        background: none;
        width: auto;
        text-align: left;
        line-height: 40px;
        padding-left: 134px;
        font-size: 26px;
        margin-top: 0;
        padding-top: 22px;
        position: relative;
        .anticon-exclamation-circle{
          position: absolute;
          left: 70px;
          top: 26px;
        }
        span{
          text-align: left;
        }
        .anticon-exclamation-circle{
          color: #FF4229;
          margin-right: 16px;
          font-size: 34px;
          vertical-align: middle;
        }
      }
    }
    .ant-modal-content .ant-modal-footer{
      padding-top: 0;
      button{
        margin-top: 0;
      }
    }
  }
  .ant-message{
    z-index: 1033 !important;
  }
}