:global{
  .teamDetail{
    width: 2480px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .teamHeader{
      width: 100%;
      height: 183px;
      border-radius: 13px;
      background-color: #fff;
      padding: 37px 50px;
      .back{
        color: #3A72FE;
        border-bottom: 1px solid #A4AFC0;
        line-height: 22px;
        font-size: 22px;
        padding-bottom: 22px;
        .backer{
          width: 18px;
          height: 18px;
          fill: #3A72FE;
        }
        span{
          cursor: pointer;
        }
      }
      .teamName{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 36px;
        font-size: 28px;
        line-height: 28px;
        cursor: pointer;
        p{
          margin-bottom: 0;
        }
        svg{
          width: 39px;
          height: 39px;
        }
      }
    }
    .teamMain{
      flex: 1;
      display: flex;
      margin-top: 10px;
      .sTit{
        font-size: 25px;
      }
      .teamDetailAndMember{
        border-radius: 13px;
        margin-right: 10px;
        background-color: #fff;
        padding: 30px 46px 30px 50px;
        box-sizing: border-box;
        width: 574px;
        .sTit{
          position: relative;
          padding-left: 40px;
          svg{
            position: absolute;
            width: 23px;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            height: 28px;
            margin-right: 10px;
            fill: #595F64;
          }
        }
        .detailAndMemberContainer{
          height: calc(100vh - 110px - 375px);
          overflow-y: auto;
          overflow-x: visible;
          margin-top: 25px;
        }
        .detailContent{
          padding-bottom: 50px;
          border-bottom: 1px solid #C9CDCE;
          label{
            color: #A5AAC1;
            font-size: 25px;
            display: inline-block;
            width: 165px;
            height: 35px;
            line-height: 35px;
          }
          span{
            width: 315px;
            display: inline-block;
            font-size: 25px;
            height: 35px;
            line-height: 35px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p:last-child{
            margin-bottom: 0;
          }
          p{
            margin: 30px 0;
            display: flex;
          }
        }
        .teamMember{
          padding-top: 60px;
          font-size: 25px;
          
          padding-right: 10px;
          label{
            color: #595F64;
            font-size: 25px;
            display: inline-block;
            width: 165px;
          }
          span{
            color: #A5AAC1;
          }
          .teamList{
            margin-top: 30px;
            .teamItem{
              width: 100%;
              height: 184px;
              background-image: url('./img/stu.png');
              background-size: 100% 100%;
              background-position: right;
              padding: 30px;
              margin-bottom: 15px;
              .stuName{
                color: #4D4D72;
                font-size: 25px;
                font-weight: bold;
                line-height: 36px;
                margin-bottom: 30px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              p{
                margin-bottom: 0;
                color: #595F64;
                font-size: 22px;
                line-height: 36px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
            .teamItem:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      .recordWrapper{
        border-radius: 13px;
        background-color: #fff;
        flex: 1;
        padding: 30px 0px;
        .pro-nav-tit{
          display: none;
        }
        .sTit{
          padding-left: 90px;
          position: relative;
          svg{
            fill: #595F64;
            position: absolute;
            width: 28px;
            height: 28px;
            left: 50px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .noDataContent{
          width: 104px;
          margin: 280px auto 0;
          .noTeam{
            height: 104px;
          }
          .noData{
            font-size: 22px;
            text-align: center;
            margin-top: 10px;
            color: #8F95B1;
          }
        }
        .padding-wrap{
          padding-top: 0;
          .record-list{
            padding-top: 0;
            width: 100%;
            height: calc(100vh - 114px - 320px);
            .charpter .record-taskList.dropActive{
              grid-template-columns: 1fr 1fr;
            }
            .item-tit{
              height: 36px;
              line-height: 36px;
              font-size: 20px;
            }
            .itemStatus{
              height: 36px;
              margin-top: 0;
              line-height: 36px;
              font-size: 16px;
              padding: 0 14px;
            }
          }
        }
      }
    }
  }
  .memberPopper{
    width: 190px;
    height: 200px;
    button{
      height: 66px;
      line-height: 66px;
      border: none;
      display: inline-block;
      width: 100%;
      text-align: left;
      padding-left: 20px;
      font-size: 20px;
      border-radius: 0;
      position: relative;
      &:hover{
        background-color: #F6F9FC;
        color: #3A72FE;
        cursor: pointer;
      }
      &:active{
        background-color: #3A72FE;
        color: #fff;
        cursor: pointer;
      }
      &::after{
        content: '';
        height: 1px;
        width: 180px;
        background-color: #A5AAC1;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    button:last-child{
      &::after{
        display: none;
      }
    }
    
  }
  .memberModal{
    width: 1200px !important;
    height: 954px;
    z-index: 1032;
    .tips{
      padding-left: 60px;
      font-size: 20px;
    }
    .ant-modal-header{
      height: 80px;
      padding-top: 0;
      padding-bottom: 0;
      background-color: #3A72FE;
      .ant-modal-title{
        line-height: 80px;
        font-size: 26px;
        color: #fff;
      }
    }
    .ant-modal-close-x{
      width: 80px;
      height: 80px;
      span{
        vertical-align: middle;
        line-height: 80px;
        color: #fff;
        font-size: 30px;
      }
    }
    .ant-modal-footer{
      text-align: center;
      border-top: none;
      height: 154px;
      button{
        width: 236px;
        height: 54px;
        font-size: 23px;
      }
      button:nth-child(1){
        margin-right: 106px;
        margin-top: 40px;
      }
    }
    .ant-transfer{
      padding-left: 60px;
      // margin: 0 auto;
      // width: 100%;
      .ant-transfer-list{
        width: 488px;
        height: 558px;
        border: none;
        .ant-transfer-list-header{
          border-bottom: none;
          font-size: 20px;
          .ant-transfer-list-header-dropdown{
            display: none;
          }
          .totalMemberInTeam{
            color: #D4D7D8;
            margin-left: 10px;
          }
        }
        .ant-transfer-list-body{
          border: 1px solid #D4D7D8;
          .ant-transfer-list-body-search-wrapper{
            padding: 0;
            .ant-input-affix-wrapper{
              border: none;
              height: 54px;
              line-height: 54px;
              border-bottom: 1px solid #D4D7D8;
              font-size: 20px;
              .ant-input{
                font-size: 20px;
              }
            }
          }
          .ant-empty-image{
            display: none;
          }
          .ant-transfer-list-content{
            .ant-transfer-list-content-item{
              height: 92px;
              position: relative;
              font-size: 20px;
              &::after{
                content: '';
                width: 450px;
                height: 1px;
                background-color: #D4D7D8;
                position: absolute;
                bottom: 0;
              }
              .item-desc{
                margin-top: 14px;
                color: #C2C5C8;
                font-size: 20px;
                .item-code{
                  margin-right: 20px;
                }
              }
            }
            .ant-transfer-list-content-item:last-child{
              // &::after{
              //   display: none;
              // }
            }
          }
          .ant-transfer-list-content-show-remove{
            .ant-transfer-list-content-item-remove{
              .anticon-delete{
                opacity:0;
              }
              &::after{
                background-image: url('./img/close.svg');
                transform: rotate(45deg);
                background-repeat: no-repeat;
                color: #A8B0B6;
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
      .ant-transfer-list:nth-child(1) {
        .ant-transfer-list-content-item{
          .ant-checkbox-wrapper{
            order: 2;
            span{
              display: none;
            }
            &::after{
              display: none;
            }
          }
        }
        .ant-transfer-list-content-item-checked{
          label{
            width: 20px;
            height: 14px;
            background-image: url('./img/right.svg');
            background-repeat: no-repeat;
          }
        }
      }
      .ant-transfer-list:nth-child(3){
        .ant-transfer-list-body-with-search {
          .ant-transfer-list-body-search-wrapper{
            display: none;
          }
          .ant-transfer-list-content {
            // padding-top: 50px;
          }
        }
        .ant-transfer-list-content-item-remove{
          svg{
            display: none;
          }
          transform: rotate(45deg);
          .anticon-delete{
            &::after{
              content:'+';
              font-size: 30px;
            }
          }
        }
      }
    }
    .formHelper{
      padding-left: 60px;
      color: #FF4229;
      float: left;
    }
  }
  .subsModal.memberModal{
    width: 770px !important;
    height: 860px;
    .ant-modal-content{
      height: 100%;
      .ant-modal-body{
        height: 570px;
        .tips{
          margin-top: 25px;
          font-size: 20px;
          width: 650px;
          span{
            float:right;
            color: #C2C5C8;
            font-size: 20px;
          }
        }
        .stuMemberList{
          margin-top: 30px;
          width: 520px;
          height: 480px;
          overflow-y: scroll;
          margin: 0 auto;
          .stuMemberItem{
            padding-top: 20px;
            height: 94px;
            border-bottom: 1px solid #D4D7D8;
            color: #595F64;
            position: relative;
            .item-tit{
              font-size: 20px;
            }
            .item-desc{
              font-size: 20px;
              color:#C2C5C8;
              .item-code{
                margin-right: 30px;
              }
            }
            .close{
              position: absolute;
              right: 20px;
              width: 16px;
              height: 16px;
              top: 30px;
              font-size: 16px;
              fill: #A8B0B6;
              cursor: pointer;
            }
          }
          .stuMemberItem.memberNodata{
            text-align: center;
            color: #C2C5C8;
            font-size: 20px;
            padding-bottom: 30px;
            height: 80px;
          }
        }
      }
    }
  }
  .ant-modal-mask{
    z-index: 1031;
  }
  .ant-modal-wrap{
    z-index: 1032;
  }
}