
:global{
  @swiperHeight: calc(100vw / 2560 * 1033);
  @topBarOriginHeight:114px;
  @titleBarOriginHeight:150px;
  @naviBarOriginHeight:185px;
  // 根据设备进行自适应185为底部导航 150为标题 114为顶部导航栏
  @totalToolHeight:calc(@topBarOriginHeight + @titleBarOriginHeight + @naviBarOriginHeight);
  @computedTopBarHeight:calc((100vh - (100vw / 2560 * 1033)) / (114 + 150 + 185) *  114);
  @computedtitleBarHeight:calc((100vh - 114px - (100vw / 2560 * 1033)) / (150 + 185) *  150);
  @computednaviBarHeight:calc((100vh - 114px - (100vw / 2560 * 1033)) / (150 + 185) *  185);
  @prowidth:2180px;
  .course{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    overflow-y: overlay;
    flex-direction: column;
    .nav-tit{
      .backTo{
        cursor: pointer;
        span{
          margin-left: 20px;
          white-space: pre-wrap;
        }
      }
    }
    .course-title{
      height: @titleBarOriginHeight;
      text-align: center;
      color: #1C2343;
      font-size: 52px;
      width: 100%;
      padding: 0 18px;
    }
    .course-list{
      padding: @titleBarOriginHeight 18px 18px;
      flex: 1;
      overflow-y: hidden;
      display: flex;
      justify-content: space-between;
      .tab-for-class-section{
        width: 500px;
        height: 100%;
        background-color: #fff;
        border-radius: 10px;
        overflow-y: overlay;
        .section{
          height: 274px;
          color:#343F4D;
          font-size: 40px;
          padding: 0 32px;
          .section-content{
            height: 100%;
            width: 100%;
            display: flex;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            position: relative;
            border-bottom: 1px solid #8F9DB1;
            p{
              width: 280px;
              height: auto;
              line-height: 60px;
            }
            .rightArr{
              display: block;
              position: absolute;
              width: 28px;
              height: 38px;
              right: 40px;
              top: 50%;
              transform: translateY(-50%);
              color: #343F4D;
              font-size: 40px;
            }
          }
          .section-content.final{
            border-bottom: none;
          }
        }
        .section.active{
          background-color: #E3F0FF;
        }
      }
      .section-list{
        width: 2010px;
        height: 100%;
        background-color: #fff;
        border-radius: 10px;
        padding: 96px 148px;
        box-sizing: border-box;
        overflow-y: scroll;
        .course-block{
          vertical-align: top;
          width: 476px;
          height: 668px;
          background-color: #fff;
          margin-bottom: 100px;
          border-radius: 10px;
          margin-right: 140px;
          display: inline-block;
          overflow:hidden;
          cursor: pointer;
          img{
            // background-color: #37347B;
            width: 100%;
            height: 290px;
            object-fit: cover;
            border-radius: 10px;
            display: block;
          }
          .course-tit{
            font-size: 43px;
            color: #343F4D;
            height: 110px;
            line-height: 53px;
            color: #343F4D;
            margin-top: 58px;
            margin-bottom: 49px;
            white-space: pre-wrap;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            -webkit-box-orient: vertical;
          }
          .course-desc{
            color: #8F9DB1;
            font-size: 36px;
            line-height: 56px;
            height: 164px;
            overflow:hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
        .course-block:nth-child(3n) {
          margin-right: 0;
        }
      }
      
    }

  }
  .course-detail-page{
    padding-top: @topBarOriginHeight !important;
    .nav-tit{
      height: @computedtitleBarHeight;
      line-height: @computedtitleBarHeight;
      top: @topBarOriginHeight !important;
      width: @prowidth;
      background: none;
      color: #fff;
      font-size: 25px;
      .backTo{
        height: @computedtitleBarHeight;
        line-height: @computedtitleBarHeight;
        font-size: 22px;
        color: #10B2FD;
      }
    }
    .course-title{
      height: @computedtitleBarHeight;
    }
    .course-list{
      padding: @computedtitleBarHeight 18px 18px;
    }
    .course-detail{
      padding-top: @computedtitleBarHeight;
      overflow: hidden;
      .mySwiper{
        height: @swiperHeight;
        .swiper-wrapper{
          height: 100%;
          .swiper-slide{
            height: 100%;
          }
        }
      }
      #loading-box{
        // height: 1210px;
        height: @swiperHeight;
        background-color: #fff;
        position: relative;
        .loading-content{
          width: 474px;
          height: 474px;
          line-height: 474px;
          border-radius: 50%;
          background: linear-gradient(-53deg, #060F90 0%, #373CDE 28%, #6E8FFF 85%);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          color: #f3f3f3;
          font-size: 32px;
          text-align: center;
          .inner-content{
            position: relative;
            height: 100%;
            width: 100%;
            
            img{
              width: 47%;
              top: 50%;
              left: 50%;
              vertical-align: bottom;
              position: absolute;
              
            }
            .rotateImg{
              -webkit-animation: myRotate 2s linear 1s 5 alternate;
              animation: myRotate 2s linear infinite; // less中没办法声明keyframe，我拿去App.css最外层声明了
            }
          }
        }
      }
      #course-navigator{
        position: fixed;
        bottom: 0;
        z-index: 10;
        background: none;
        display: flex;
        width: @prowidth;
        justify-content: space-between;
        color: #35BFFF;
        font-size: 22px;
        line-height: 1;
        height: @computednaviBarHeight;
        padding: 0;
        box-sizing: border-box;
        .pre,.next,.page-counter{
          line-height: @computednaviBarHeight;
          width: 700px;
        }
        .preWithArr{
          padding-left: 40px;
          position: relative;
          color: #35BFFF;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          .iconfont{
            position: absolute;
            left: 0;
            transform: rotate(180deg);
            font-size: 30px;
          }
        }
        .afterWithArr{
          padding-right: 40px;
          position: relative;
          color: #35BFFF;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          .iconfont{
            position: absolute;
            right: 0;
            font-size: 25px;
            top: 0;
          }
        }
        .afterWithArr.readyToUpdate{
          color: #7C8599;
        }
        .page-counter{
          text-align: center;
          color: #fff;
          font-size: 20px;
        }
        .next{
          text-align: right;
        }
      }
      #course-block{
        min-height: 1210px;
        background-color: #fff;
        background: none;
        padding-bottom: @computednaviBarHeight;
        width: 100%;
        .img-block{
          position: relative;
          overflow: hidden;
          z-index: 0;
          display: flex;
          align-items: center;
          // margin-bottom: 20px;
          height: 100%;
          justify-content: center;
          img{
            max-width: 100%;
            max-height: 100%;
            // object-fit: cover;
            object-position: center;
            display: block;
          }
          .animation{
            position: absolute;
          }
          .PPT2-p20{
            width: 400px;
            height: 400px;
            right: 25%;
            top: 50%;
            transform: translateY(-50%);
          }
          .PPT4-p6{
            height: 600px;
            right: 15%;
            top: 50%;
            transform: translateY(-10%);
          }
          .PPT4-p17-1{
            height: 350px;
            left: 34%;
            top: 33%;
            transform: translateY(-10%);
          }
          .PPT4-p17-2{
            height: 350px;
            left: 34%;
            top: 63%;
            transform: translateY(15%);
          }
          .PPT5-p5-1,.PPT5-p5-2,.PPT5-p5-3,.PPT5-p5-4{
            height: 630px;
            left: 2%;
            top: 30%;
            transform: translateY(15%);
          }
          .PPT5-p5-2{
            left: 22%;
            transform: translateY(20%);
          }
          .PPT5-p5-3{
            left: 55%;
          }
          .PPT5-p5-4{
            left: 75%;
            transform: translateY(20%);
          }
          .PPT8-p4{
            left: unset;
            right: 5%;
            top: 50%;
            width: 800px;
          }
          .PPT8-p5{
            left: 50%;
            top: 25%;
            transform: translateX(-50%);
            width: 50%;
          }
          .PPT8-p6{
            left: 50%;
            transform: translate(-50%,-30%);
            top: 55%;
            width: 50%;
          }
          .PPT8-p11,.PPT8-p12,.PPT8-p14 {
            width: 40%;
            right: 85px;
            left: unset;
            top: 55%;
            transform: translateY(-50%);
          }
          .PPT9-p3-1{
            width: 40%;
            left: 6%;
          }
          .PPT9-p3-2{
            width: 40%;
            right: 7%;
          }
          .PPT9-p8{
            width: 60%;
            right: 5%;
          }
          .PPT9-p9{
            width: 50%;
            left: 5%;
          }
          .PPT9-p13{
            width: 50%;
            left: 5%;
            top: 50%;
            transform: translateY(-40%);
          }
          .PPT15-superconduct-max{
            width: 1600px;
            height: 800px;
            left: 50%;
            top: 60%;
            transform: translate(-50%,-50%);
          }
          .linkToTask{
            position: absolute;
            right: 2%;
            bottom: 2.5%;
            padding: 22px 60px;
            min-width: 218px;
            height: 40px;
            border-radius:40px;
            background-color: #673CE2;
            line-height:  40px;
            color: #fff;
            text-align: center;
            .button-content {
              position: relative;
              display: inline-block;
              .icon-wrapper {
                width: 38px;
                height: 38px;
                float: left;
                position: relative;
                margin-right: 40px;
                fill: rgb(255, 251, 199);
              }
              .tips {
                float: left;
                position: relative;
                font-size: 36px;
              }
            }
          }
          .linkToTask.option{
            .icon-wrapper{
              display: none;
            }
            background-color: #007CFF;
            bottom: 3%;
          }
        }
        .finished{
          height: @swiperHeight;
          position: relative;
          background-color: #fff;
          .star{
            width: 750px;
            height: 700px;
            position: absolute;
            left: 50%;
            top: 50%;
            display: block;
            transform: translate(-50%,-50%);
            img{
              width: 440px;
              height: 427px;
              display: block;
              margin: 0 auto;
            }
            p{
              text-align: center;
              color: #7E8998;
              margin-top: 20px;
              font-size: 36px;
            }
            button{
              height: 80px;
              margin-top: 40px;
              font-size: 36px;
              background-color: #673CE2;
            }
          }
        }
      }
    }
    #course-navigator{
      height: @computednaviBarHeight;
      .pre,.next,.page-counter{
        line-height: @computednaviBarHeight;
      }
    }
    #course-block{
      min-height: 1210px;
      background-color: #fff;
      // padding-bottom: @computednaviBarHeight;
    }
  }
  .enApp {
    .course .course-list {
      .tab-for-class-section .section .section-content p{
        width: 290px;
      }
      .section-list .course-block {
        height: 680px;
        .course-tit{
          height: 160px;
          margin-bottom: 16px;
          -webkit-line-clamp: 3;
        }
        .course-desc{
          line-height: 52px;
        }
      }
    }
    .course-detail-page .course-detail #course-block .img-block .PPT2-p20{
      width: 400px;
      height: 400px;
      top: 46%;
    }
  }
  // 处理pc pro页面样式
  .pro .course.padding-wrap{
    width: 2180px;
    margin: 0 auto;
    padding-top: 174px;
    .course-list{
      // padding-top: 72px;
      padding: 0 0 40px 0;
    }
    .tab-for-class-section{
      width: 310px;
      margin-right: 12px;  
      .section{
        height: 168px;
        .section-content {
          justify-content: start;
          color: #8F95B1;
          p{
            font-size: 22px;
            line-height: 32px;
            width: 90%;
            margin-bottom: 0;
          }
          .rightArr{
            color: #fff;
            width: 20px;
            height: 25px;
            right: -10px;
          }
        }
      }
      .section:nth-child(2){
        white-space: pre;
      }
      .section.active{
        background-color: #3A72FE;
        .section-content{
          color: #fff;
        }
      }
    }
    .section-list{
      padding-bottom: 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 1860px;
      padding: 93px 113px;
      .course-block{
        margin-right: 220px;
        display: block;
        width: 355px;
        height: 450px;
        .course-img{
          width: 100%;
          height: 220px;
          img{
            height: 100%;
          }
        }
        .course-content{
          
          .course-tit{
            font-size: 25px;
            margin-top: 35px;
            line-height: 30px;
            height: 74px;
            margin-bottom: 30px;
            color: #595F64;
          }
          .course-desc{
            font-size: 21px;
            line-height: 32px;
          }
        }
      }
      .course-block:nth-child(3n) {
        margin-right: 0;
      }
    }
    #course-block .img-block {
      .linkToTask{
        height: auto;
        padding: 10px 30px;
        height: 60px;
        .button-content {
          .icon-wrapper{
            width: 34px;
            height: 34px;
            margin-right: 16px;
          }
          .tips {
            font-size: 22px;
          }
        }
      }
    }
  }
  .pro.enApp{
    .course.padding-wrap .section-list .course-block {
      height: 470px;
      .course-content {
        .course-tit{
          height: 90px;
        }
        .course-desc{
          height: 100px;
        }
      }
    }
  }
}
