:global{
  .groupManager{
    display: flex;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    .classListManager{
      padding-top: 50px;
      width: 494px;
      background-color: #fff;
      border-right: 1px solid #8F95B1;
      position: relative;
      .tag{
        position:relative;
        text-align: left;
        svg{
          content:'';
          width: 34px;
          height: 32px;
          display: inline-block;
          position: absolute;
          left: 55.5px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .content{
        overflow-y: scroll;
        height: calc(100vh - 106px - 90px - 40px);
        padding-bottom: 60px;
        border-bottom: 60px solid #fff;
        .blankWrap{
          height: calc(100vh - 236px);
          position: relative;
          button{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .blank{
          width: 300px;
          height: 300px;
          position: absolute;
          top: 390px;
          left: 50%;
          transform: translateX(-50%);
          img{
            width: 200px;
            display: block;
            margin: 0 auto;
          }
          svg{
            width: 104px;
            height: 104px;
            display: block;
            margin: 0 auto;
          }
          .classGroupBtn{
            position: absolute;
            top: 550px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .timeout.blankWrap{
          .blank{
            top: 300px;
          }
        }
        p {
          margin-top: 28px;
          white-space: nowrap;
          text-align: center;
          // position: absolute;
          // top: 460px;
          width: 100%;
          color: #8F95B1;
          font-size: 22px;
        }
        
        .classGroupBtn{
          width: 380px;
          height: 50px;
          color: #3A72FE;
          font-size: 22px;
          margin: 0 auto;
          display: block;
          position: absolute;
          bottom: 10px;
          left: 40px;
          // left: 50%;
          // transform: translateX(-50%);
        }
        .courseItem:nth-child(1) {
          margin-top: 30px;
        }
        .courseItem{
          width: 380px;
          // margin: 16px auto;
          margin-top: 16px;
          margin-left: 40px;
          padding: 0 17px 17px 17px;
          border: 1px solid #C9CDCE;
          color: #595F64;
          font-size: 22px;
          line-height: 40px;
          .indBar{
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 18px;
            line-height: 40px;
            border-bottom: 1px solid #C9CDCE;
          }
          .anticon-ellipsis{
            text-align: right;
            line-height: 40px;
            font-size: 22px;
          }
          &:hover{
            cursor: pointer;
            border: 1px solid #3A72FE;
          }
          .groupNameContent{
            min-height: 100px;
            line-height: 34px;
            word-break: break-all;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display:-webkit-box; //作为弹性伸缩盒子模型显示。 
            // -webkit-line-clamp: 3;
            // -webkit-box-orient: vertical;
          }
        }
        .active.courseItem{
          background-color: #3A72FE;
          color: #fff;
        }
      }
    }
    .studyGroupManager{
      width: 1665px;
      padding-top: 50px;
      background-color: #fff;
      position: relative;
      .addTeam{
        color: #3A72FE;
        // margin: 20px 0 0 20px;
        position: absolute;
        top: 120px;
        left: 40px;
        font-size: 22px;
        z-index: 9;
        padding: 0 0 0 ;
        line-height: 16px;
      }
      .tag{
        position:relative;
        text-align: left;
        svg{
          content:'';
          width: 48px;
          height: 38px;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 40px;
          transform: translateY(-50%);
        }
      }
      .blankWrap{
        height: calc(100vh - 236px);
        position: relative;
        p {
          margin-top: 28px;
          white-space: nowrap;
          text-align: center;
          // position: absolute;
          // top: 470px;
          width: 100%;
          color: #8F95B1;
          font-size: 22px;
        }
      }
      .blank{
        width: 304px;
        height: 304px;
        top: 390px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        svg{
          width: 104px;
          height: 104px;
          margin: 0 auto;
          display: block;
        }
        img{
          width: 200px;
          display: block;
          margin: 0 auto;
        }
      }
      .teamItem{
        width: 1430px;
        height: 150px;
        border: 1px solid #C9CDCE;
        margin-left: 40px;
        padding: 25px 35px;
        color: #595F64;
        font-size: 25px;
        margin-bottom: 20px;
        cursor: pointer;
        &:hover{
          border-color: #3A72FE;
          .teamTitle{
            color: #3A72FE;
          }
        }
        .teamTitle{
          line-height: 25px;
          color: #595F64;
        }
        .teamAttrs{
          display: flex;
          justify-content: flex-start;
          margin-top: 50px;
          color: #A5AAC1;
          .svgIconInTeamList{
            vertical-align: bottom;
            margin-right: 10px;
            fill: #A5AAC1;
            &.memberIcon{
              width: 22px;
              height: 28px;
            }
            &.completeIcon{
              width: 24px;
              height: 28px;
            }
            &.correctIcon{
              width: 22px;
              height: 26px;
            }
          }
          p{
            width: 360px;
            font-size: 22px;
            margin-bottom: 0;
            line-height: 22px;
          }
        }
      }
      .content{
        height: calc(100vh - 106px - 90px - 40px);
        border-top: 90px solid #fff;
        overflow-y: scroll;
        .teamItem:nth-child(1) {
          // margin-top: 90px;
        }
      }
    }
    .tag{
      padding-left: 110px;
      font-size: 25px;
      color: #595F64;
      height: 40px;
      line-height: 40px;
    }
  }
  .groupManagerModal{
    width: 900px !important;
    height: 590px;
    padding-bottom: 0;
    .ant-modal-content{
      height: 100%;
      .ant-modal-header{
        height: 80px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #3A72FE;
        .ant-modal-title{
          line-height: 80px;
          font-size: 26px;
          color: #fff;
        }
      }
      .ant-modal-close-x{
        width: 80px;
        height: 80px;
        span{
          vertical-align: middle;
          line-height: 80px;
          color: #fff;
          font-size: 30px;
        }
      }
      .ant-modal-body{
        height: 390px;
        .tips{
          width: 740px;
          height: 46px;
          line-height: 46px;
          padding-left: 20px;
          // background-color: #F0F0F0;
          margin: 40px auto;
          .anticon-info-circle,.freshBtn{
            color: #3A72FE;
          }
          p{
            margin-bottom: 10px;
          }
          .showLen{
            text-align: right;
            color: #D2D2D2;
            font-size: 20px;
            position: absolute;
            bottom: 0;
            right: 0;
          }
          .createTeamTips{
            font-size: 19px;
            .subTips{
              color: #ACACAC;
            }
          }
          .ant-select-selection-placeholder{
            line-height: 50px;
            font-size: 20px;
          }
        }
        .ant-form{
          position: relative;
          height: 100px;
          .ant-form-item{
            margin-bottom: 60px;
            .ant-select-selection-search-input{
              font-size: 20px;
            }
          }
          .ant-form-item-explain-success{
            color: #FF4229;
            font-size: 20px;
            margin-top: 20px;
            text-align: left;
            transition: none;
            position: absolute;
            bottom: -26px;
            left: 0;
          }
          .showLen{
            position: absolute;
            right: 0;
            bottom: -10px;
            font-size: 20px;
          }
          .showLen.warning{
            color: #FF4229;
          }
        }
        input{
          width: 100%;
          display: inline-block;
          border: 1px solid #C9CDCE;
          height: 50px;
          font-size: 20px;
          &::placeholder{
            color: #D2D2D2;
          }
          padding-left: 14px;
        }
        font-size: 19px;
        .ant-btn{
          font-size: 20px;
        }
        .chooseFile{
          margin-left: 60px;
          width: 740px;
          white-space: nowrap;
          color: #595F64;
          
          position: relative;
          .labelInFile{
            width: 110px;
            display: inline-block;
          }
          .fileName{
            display: inline-block;
            width: 590px;
            line-height: 30px;
            vertical-align: middle;
            border-bottom: 1px solid #D0D0E0;
            overflow: hidden;
            text-overflow: ellipsis;
            .anticon-close{
              color: #D0D0E0;
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .ant-btn{
            border-color: #3A72FE;
            color: #3A72FE;
            height: 54px;
            line-height: 44px;
            width: 238px;
            vertical-align: middle;
            // margin-left: 10px;
            margin-right: 10px;
          }
        }
        .fileTips{
          color: #A5AAC1;
          font-size: 21px;
          padding-left: 170px;
          margin-top: 10px;
        }
        .errorType{
          color: #FF4229;
        }
      }
      .ant-modal-footer{
        text-align: center;
        border-top: none;
        button{
          width: 236px;
          height: 54px;
          font-size: 23px;
          margin-top: 0;
        }
        button:nth-child(1){
          margin-right: 106px;
        }
        button:disabled{
          background-color: #f0f0f0;
        }
      }
    }
    .ant-form-item-explain-connected{
      color: #FF4229;
      position: absolute;
      bottom: -40px;
    }
    .ant-btn-default{
      color: #3A72FE;
      border-color: #BAC3CF;
    }
    .ant-btn-primary{
      background-color: #3A72FE;
    }
    .ant-select{
      width: 100%;
      height: 52px;
      .ant-select-selection-item{
        line-height: 52px;
        font-size: 20px;
      }
      .ant-select-selector{
        height: 100%;
        line-height: 52px;
      }
      .ant-select-arrow{
        width: 32px;
        height: 12px;
        .anticon{
          width: 100%;
        }
      }
    }
  }
  .groupPopperButton{
    margin-top: -10px;
    border: 1px solid #CFD3D4;
    border-radius: 5px;
    overflow: hidden;
    .ant-popover-inner-content{
      padding: 0;
    }
    button{
      display: block;
      border: none;
      border-radius: 0;
      width: 190px;
      padding: 0 10px 0 30px;
      height: 60px;
      font-size: 20px;
      line-height: 60px;
      position: relative;
      border-radius: 5px;
      text-align: left;
      &:hover{
        background-color: #F6F9FC;
        color: #3A72FE;
      }
      &:active{
        background-color: #3A72FE;
        color: #fff;
      }
    }
    .lineInBtn{
      width: 170px;
      height: 1px;
      background-color: #C1C9D4;
      margin-left: 10px;
    }
  }
  .ant-popover-arrow-content::before{
    left: -40px;
  }
  .ant-popover-inner-content{
    padding: 0;
  }
  .selOptInCreateTeam{
    height: 50px;
    line-height: 50px;
    display: block;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 22px;
    &:hover{
      background-color: #F6F9FC;
      color: #3A72FE;
    }
    &:active{
      background-color: #3A72FE;
      color: #fff;
    }
  }
}