:global{

  @star-map-width: 1600px;
  @star-map-height: 1600px;
  @star-anime-length: 8.5s;

  .returnButton-wrapper {
    position: absolute;
    background-position: center;
    background-size: cover;
    width: 369px;
    height: 168px;
    top: 112px;
    .returnButton {
      font-size: 36px;
      height: 88px;
      line-height: 88px;
      // width: 288px;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-60%);
    }
    text-align: center;
    color: #10B2FD;
    // background-image: url('img/QuestionList/return-background.png'); 
  }

  .sideButton {
    position: absolute;
    color: #05C3FF;
    font-size: 100px !important;
    font-weight: bold;
    top: 50%;
    transform: translateY(-50%) scaleY(1.2);
    padding: 300px 50px;
    &.left {
      padding-right: 100px;
    }
    &.right {
      right: 0;
      padding-left: 100px;
    }
  }

  .questionTest {
    position: absolute;
    top: 1300px;
    left: 200px;
    .question {
      float: left;
      margin-right: 20px;
      width: 70px;
      height: 70px;
      background-color: rgb(184, 184, 184);

      &.done {
        background-color: rgb(2, 104, 2);
      }
    }
    .submit {
      float: left;
      width: 70px;
      height: 70px;
      background-color: rgb(24, 131, 231);
    }
  }

  .questionlist-wrapper {
    background-position: center;
    background-size: cover;
    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    // background-image: url('img/QuestionList/background.png'); 
    // background-color: #07015b;
    z-index: 0;
    overflow: hidden;
    .title {
      position: absolute;
      color: #05C3FF;
      font-size: 42px;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      top: 164px;
    }

    .tips {
      position: absolute;
      color: #05C3FF;
      font-size: 32px;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      bottom: 74px;
    }

    .star-label {
      min-width: 618px;
      height: 105px;
      line-height: 105px;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 134px;
      background-position: center;
      background-size: cover;
      background-image: url('img/QuestionList/star-label-background.png'); 
      font-size: 50px;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      span{
        padding: 0 30px;
      }
    }
  }

  .star-map-wrapper {
    position: relative;
    height: @star-map-height;
    width: @star-map-width;
    margin: 0 auto;
    top: 47%;
    transform: translateY(-50%) translateZ(0);
    .star-figure {
      // will-change: transform;
      background-position: center;
      background-size: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0;
      // transition: opacity 4.5s;
      
      // transform: translateZ(0);
      &.shining {
        animation: star-figure-shine @star-anime-length linear 1;
        animation-delay: 1s;
        animation-fill-mode: forwards;
      }

      &.active {
        opacity: 0.63;
        animation: opacity-ease-in .3s linear 1;
      }

      &#star-figure-1 {
        background-image: url('img/QuestionList/starFigure/1-shine.png'); 
      }

      &#star-figure-2 {
        background-image: url('img/QuestionList/starFigure/2-shine.png'); 
      }
  
      &#star-figure-3 {
        background-image: url('img/QuestionList/starFigure/3-shine.png'); 
      }

      &#star-figure-4 {
        background-image: url('img/QuestionList/starFigure/4-shine.png'); 
      }

      &#star-figure-5 {
        background-image: url('img/QuestionList/starFigure/5-shine.png'); 
      }

      &#star-figure-6 {
        background-image: url('img/QuestionList/starFigure/6-shine.png'); 
      }
  
      &#star-figure-7 {
        background-image: url('img/QuestionList/starFigure/7-shine.png'); 
      }

      &#star-figure-8 {
        background-image: url('img/QuestionList/starFigure/8-shine.png'); 
      }

      &#star-figure-9 {
        background-image: url('img/QuestionList/starFigure/9-shine.png'); 
      }

      &#star-figure-10 {
        background-image: url('img/QuestionList/starFigure/10-shine.png'); 
      }
      &#star-figure-11 {
        background-image: url('img/QuestionList/starFigure/11-shine.png'); 
      }
      &#star-figure-12 {
        background-image: url('img/QuestionList/starFigure/12-shine.png'); 
      }
      &#star-figure-13 {
        background-image: url('img/QuestionList/starFigure/13-shine.png'); 
      }
      &#star-figure-14 {
        transform: scale(1.15);
        background-image: url('img/QuestionList/starFigure/14-shine.png'); 
      }
      &#star-figure-15 {
        background-image: url('img/QuestionList/starFigure/15-shine.png'); 
        background-position: 54% 48%;
        transform: translateY(-20px);
      }
      &#star-figure-16 {
        background-image: url('img/QuestionList/starFigure/16-shine.png'); 
      }
    }
  
    .star-line {
      background-position: center;
      background-size: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      // z-index: 2;
      .shine-window {
        position: absolute;
        top: 0px;
        height: 0px;
        width: 0px;
        overflow: hidden;
        &.shining {
          animation: star-line-shine @star-anime-length linear 1;
          animation-fill-mode: forwards;
        }

        &.active {
          height: 100%;
          width: 100%;
        }

        .shine {
          height: @star-map-height;
          width: @star-map-width;
          background-position: center;
          background-size: cover;
        }
      }

      &#star-line-1 {
        background-image: url('img/QuestionList/starLine/1.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/1-shine.png'); 
        }
      }

      &#star-line-2 {
        background-image: url('img/QuestionList/starLine/2.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/2-shine.png'); 
        }
      }

      &#star-line-3 {
        background-image: url('img/QuestionList/starLine/3.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/3-shine.png'); 
        }
      }

      &#star-line-4 {
        background-image: url('img/QuestionList/starLine/4.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/4-shine.png'); 
        }
      }

      &#star-line-5 {
        background-image: url('img/QuestionList/starLine/5.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/5-shine.png'); 
        }
      }

      &#star-line-6 {
        background-image: url('img/QuestionList/starLine/6.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/6-shine.png'); 
        }
      }

      &#star-line-7 {
        background-image: url('img/QuestionList/starLine/7.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/7-shine.png'); 
        }
      }

      &#star-line-8 {
        background-image: url('img/QuestionList/starLine/8.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/8-shine.png'); 
        }
      }

      &#star-line-9 {
        background-image: url('img/QuestionList/starLine/9.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/9-shine.png'); 
        }
      }

      &#star-line-10 {
        background-image: url('img/QuestionList/starLine/10.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/10-shine.png'); 
        }
      }
      
      &#star-line-11 {
        background-image: url('img/QuestionList/starLine/11.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/11-shine.png'); 
        }
      }

      &#star-line-12 {
        background-image: url('img/QuestionList/starLine/12.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/12-shine.png'); 
        }
      }

      &#star-line-13 {
        background-image: url('img/QuestionList/starLine/13.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/13-shine.png'); 
        }
      }

      &#star-line-14 {
        transform: scale(1.07);
        background-image: url('img/QuestionList/starLine/14.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/14-shine.png'); 
        }
      }

      &#star-line-15 {
        background-image: url('img/QuestionList/starLine/15.png'); 
        .shine {
          background-image: url('img/QuestionList/starLine/15-shine.png'); 
        }
      }

      &#star-line-16 {
        background-image: url('img/QuestionList/starLine/16.png');
        .shine {
          background-image: url('img/QuestionList/starLine/16-shine.png'); 
        }
      }
    }
  
    .success-txt {
      color: #fffbc7;
      position: absolute;
      font-size: 42px;
      line-height: 60px;
      width: 252px;
      text-align: left;
      opacity: 0;

      &.shining {
        animation: success-txt-shine @star-anime-length linear 1;
        animation-fill-mode: forwards;
      }

      &.active {
        opacity: 1;
      }

      &#success-txt-course-1 {
        top: 850px;
        left: 1020px;
      }

      &#success-txt-course-2 {
        top: 520px;
        left: 820px;
      }

      &#success-txt-course-3 {
        top: 390px;
        left: 500px;
      }

      &#success-txt-course-4 {
        top: 540px;
        left: 1160px;
      }

      &#success-txt-course-5 {
        top: 730px;
        left: 1140px;
      }

      &#success-txt-course-6 {
        top: 1120px;
        left: 820px;
      }

      &#success-txt-course-7 {
        top: 540px;
        left: 380px;
      }

      &#success-txt-course-8 {
        top: 1100px;
        left: 380px;
      }

      &#success-txt-course-9 {
        top: 1170px;
        left: 1080px;
      }

      &#success-txt-course-10 {
        top: 1040px;
        left: 1040px;
      }
      &#success-txt-course-11 {
        top: 1140px;
        left: 1140px;
      }
      &#success-txt-course-12 {
        top: 1200px;
        left: 1200px;
      }
      &#success-txt-course-13 {
        top: 1200px;
        left: 1200px;
      }
      &#success-txt-course-14 {
        top: 1100px;
        left: 1200px;
      }
      &#success-txt-course-15 {
        top: 1100px;
        left: 1100px;
      }
      &#success-txt-course-16 {
        top: 1000px;
        left: 1100px;
      }
    }
  }
  // @media (min-aspect-ratio: 1920/1080) {
  //   .star-map-wrapper{
  //     transform: translateY(-45%) scale(0.85);
  //   }
  // }

  @media (min-aspect-ratio: 2561/1600) and (max-aspect-ratio: 1919/1080) {
    .star-map-wrapper{
      transform: translateY(-50%) scale(0.85);
    }
  }
  @media (min-aspect-ratio: 2176/1600) and (max-aspect-ratio: 2560/1600) {
    .star-map-wrapper{
      transform: translateY(-50%) scale(1.1);
    }
  }
  @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 2176/1600) {
    .star-map-wrapper{
      transform: translateY(-50%) scale(1.2);
    }
  }
  @media (aspect-ratio: 1/1){
    .star-map-wrapper{
      transform: translateY(-50%) scale(1.6);
    }
  }
  .enApp{
    .questionlist-wrapper .star-label .cn-name{
      display: none;
    }
    .star-map-wrapper .success-txt{
      width: 460px;
    }
  }

  .app.pro{
    .question-nav{
      background-color: transparent;
      color: #fff;
      position: absolute;
      width: 75%;
      margin: 0 auto;
      left: 50%;
      height: 90px;
      line-height: 90px;
      transform: translateX(-50%);
      .returnButton-wrapper{
        height: 90px;
        line-height: 90px;
        display: block;
        width: 100%;
        top: 0;
        position: relative;
        .returnButton{
          font-size: 24px;
          text-align: left;
          
        }
        .title{
          color: #fff;
          position: absolute;
          height: 90px;
          line-height: 90px;
          font-size: 24px;
          vertical-align: top;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
    .questionlist-wrapper {
      transform: scale(0.75);
      border-radius: 10px;
      overflow: hidden;
      margin-top: 70px;
      .title{
        font-size: 35px;
        top: 60px;
      }
      .star-label{
        height: 89px;
        line-height: 89px;
        font-size: 42px;
        background-size: auto 100%;
        background-repeat: no-repeat;
      }
      .tips{
        font-size: 27px;
      }
    }
    .star-map-wrapper {
      .success-txt{
        font-size: 35px;
      }
    }
    .sideButton{
      font-size: 85px !important;
      &.left{
        padding-left: 120px;
        left: 300px;
      }
      &.right{
        padding-right: 120px;
        right: 300px;
      }
    }
  }
}