:global {
  .record-list{
    padding: 56px 82px 56px 38px;
    min-height: 100vh;
    background-color: #fff;
    overflow-x: hidden;
    .status{
      p{
        text-align: right;
      }
      font-size: 37px;
      .label{
        color: #7E8998;
        margin-left: 111px;
      }
      .recordNum{
        color: #1C2343;
        margin-left: 22px;

      }
    }
    .charpter{
      padding-top: 40px;
      
      .ctitle{
        line-height: 146px;
        height: 146px;
        padding-left: 36px;
        position: relative;
        font-size: 46px;
        &::before{
          content: '';
          width: 10px;
          height: 10px;
          background-color: #000;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      
      .lessonWrapper{
        margin-left: 53px;
        padding:90px  0;
        border-bottom: 1px solid #E0E6F0;
      }
      .lessonWrapper:nth-child(2){
        // margin-top: -30px;
        padding-top: 40px;
      }
      .lessontit{
        // padding-left: 53px;
        padding-left: 10px;
        height: 66px;
        line-height: 66px;
        font-size: 42px;
        display: flex;
        justify-content: space-between;
        .dropDown{
          cursor: pointer;
          // color: #575FFC;
          moz-user-select: -moz-none; 
          -moz-user-select: none; 
          -o-user-select:none; 
          -khtml-user-select:none; 
          -webkit-user-select:none; 
          -ms-user-select:none; 
          user-select:none;
          .rightArr{
            transform: rotate(90deg);
            transition: all .5s;
            margin-right: 14px;
          }
          .dropActive{
            transform: rotate(270deg);
          }
        }
      }
      .record-taskList{
        // display: none;
        // overflow: hidden;
        // max-height: 0;
        height: auto;
        width: 100%;
        margin-left: 53px;
        transition: max-height .5s linear;
        // border-bottom: 1px solid #E0E6F0;
        .tag{
          height: 60px;
        }
        
        .recordItem{
          max-width: 550px;
          height: 0;
          box-sizing: border-box;
          overflow: hidden;
          display: block;
          transition: all .2s;
          .clear{clear:both; height: 0; line-height: 0; font-size: 0}
        }
        .item-tit{
          color: #1F4DE0;
          // font-size: 42px;
          line-height: 40px;
          float: left;
          display: block;
          height: 100%;
        }
        .item-desc{
          margin-top: 30px;
          font-size: 37px;
          color: #7E8998;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .itemStatus{
          float: left;
          color: #00AA87;
          padding: 0 20px;
          font-size: 22px;
          height: 40px;
          line-height: 41px;
          margin-left: 20px;
          // vertical-align: middle;
          // margin-top: 5px;
          border: 1px solid #00AA87;
          border-radius: 36px;
        }
        .itemStatus.failed{
          font-size: 22px;
          color: #ED4B30;
          border-color: #ED4B30;
        }
      }

    }
  }
  .app.pro {
    .record-list{
      width: 2180px;
      height: calc(100vh - 80px - 96px - 40px);
      min-height: unset;
      overflow-y:scroll;
      box-sizing: border-box;
      margin: 0 auto;
      border-radius: 14px;
      padding: 46px 58px;
      .status{
        font-size: 22px;
        .recordNum{
          margin-left: 0;
          color: #595F64;
        }
        .label{
          color: #A5AAC1;
        }
      }
      .charpter{
        padding-top: 0;
        .ctitle{
          font-size: 28px;
          height: 36px;
          line-height: 36px;
          color: #595F64;
          padding-left: 10px;
          &::before{
            display: none;
          }
        }
        .ctitle:nth-child(n+1){
          margin-top: 60px;
        }
        .lessonWrapper{
          padding-top: 50px;
          padding-bottom: 50px;
          padding-left: 0;
          margin-left: 0;
          .lessontit{
            font-size: 27px;
            height: 32px;
            line-height: 32px;
            color: #595F64;
            .dropDown{
              color: #3A72FE;
              font-size: 20px;
            }
          }
        }
        .lessonWrapper:nth-child(2){
          // margin-top: -30px;
          // padding-top: 30px;
        }
        .record-taskList{
          display: block;
          height: auto;
          .recordItem{
            padding-top: 0;
            // max-height: 0;
            height: 0;
            box-sizing: content-box;
            .tag{
              height: 30px;
              line-height: 30px;
            }
            .item-tit{
              font-size: 22px;
              line-height: 30px;
              color: #32325D;
              font-weight: 900;
            }
            .itemStatus{
              color: #21AE0A;
              font-size: 22px;
              border: none;
              line-height: 30px;
              height: 30px;
              margin-left: 16px;
            }
            .itemStatus.failed{
              color: #FF4229;
            }
            .item-desc{
              font-size: 22px;
              line-height: 28px;
              height: 28px;
              margin-top: 22px;
            }
          }
        }
        .record-taskList.dropActive{
          display: inline-grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-row-gap:0px;
          margin-left: 0;
          .recordItem{
            height: 104px;
            box-sizing: border-box;
            // max-height: auto;
            padding-top: 10px;
            padding-left: 10px;
            margin-top: 35px;
            cursor: pointer;
            &:hover{
              background-color: #EFF2FF;
              border-radius: 13px;
            }
          }
        }
      }
    }
  }
  .selfModalContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    .selfModalContent{
      position: relative;
      width: 100%;
      height: 100%;
      .selfModalAbsolute{
        position: absolute;
        width: 2021px;
        height: calc(100vh - 120px);
        top: 120px;
        left: 50%;
        transform: translateX(-50%) scale(0.65);
        .taskHead{
          width: 100%;
          height: 62px;
          position: relative;
          background-color: #0078FF;
          .taskTitle{
            color: #fff;
            padding-left: 20px;
            line-height: 62px;
            font-size: 24px;
          }
          span{
            width: 30px;
            height: 30px;
            display: inline-block;
            font-size: 60px;
            right: 20px;
            position: absolute;
            top: 20px;
            transform: translateY(-50%) rotate(45deg);
            color: #fff;
            line-height: 30px;
            cursor: pointer;
          }
        }
        .task-list, #nav-tit{
          display: none;
        }
        .padding-wrap{
          width: 100%;
          transform: scale(1);
          margin-top: 0;
          .task-detail{
            margin-left: 0;
          }
          .submit-line{
            display: none;
          }
        }
      }
    }
  }
}