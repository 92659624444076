
:global{
  @swiperHeight: calc(100vw / 2560 * 1033);
  @topBarOriginHeight:114px;
  @titleBarOriginHeight:150px;
  @naviBarOriginHeight:185px;
  // 根据设备进行自适应185为底部导航 150为标题 114为顶部导航栏
  @totalToolHeight:calc(@topBarOriginHeight + @titleBarOriginHeight + @naviBarOriginHeight);
  @computedTopBarHeight:calc((100vh - (100vw / 2560 * 1033)) / (114 + 150 + 185) *  114);
  @computedtitleBarHeight:calc((100vh - 114px - (100vw / 2560 * 1033)) / (150 + 185) *  150);
  @computednaviBarHeight:calc((100vh - 114px - (100vw / 2560 * 1033)) / (150 + 185) *  185);
  @prowidth:2180px;
  .user{
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: auto 100%;
    // background-color: #fff;
    text-align: center;
    &.login-page{
      background-image: url(https://static-cdn.spinq.cn/edu/eduLogin.png);
      background-color: #fff;
    }
    .loginWrapper{
      position: relative;
      .loginBlock{
        position: absolute;
        right: 293px;
        width: 610px;
        height: 730px;
        box-shadow: 0px 4px 11px 0px rgba(35,89,216,0.2);
        background: rgba(250,251,255,0.95);
        border-radius: 6px;
        padding: 0 30px;
        .loginType{
          width: 446px;
          display: flex;
          margin: 50px auto 30px;
          justify-content: space-between;
          padding: 0 70px;
          .loginItem{
            font-size: 22px;
            line-height: 46px;
            cursor: pointer;
            &.active{
              color: #1E73FF;
              border-bottom: 3px solid #1E73FF;
            }
          }
        }
        .loginBody{
          padding-top: 54px;
          position: relative;
          .welcome{
            color: #1E73FF;
            font-size: 43px;
            text-align: center;
            line-height: 45px;
          }
          .subTit{
            color: #595F64;
            font-size: 31px;
          }
          .inputBlock{
            margin-top: 30px;
            input{
              width: 446px;
              height: 52px;
              border: 1px solid #C9CDCE;
              margin-bottom: 25px;
              font-size: 20px;
            }
            .ant-input-password{
              width: 446px;
              height: 52px;
              border: 1px solid #C9CDCE;
              margin-bottom: 25px;
              font-size: 20px;
              input{
                border: none;
                height: auto;
                margin-bottom: 0;
              }
            }
          }
          .loginBtn{
            margin-top: 37px;
            width: 446px;
            height: 52px;
            background-color: #3A72FE;
            color: #fff;
            margin-bottom: 177px;
            font-size: 22px;
            &.disabled{
              background-color: #CFCFCF;
              
            }
          }
          p{
            display: flex;
            justify-content: space-between;
            width: 446px;
            margin: 0 auto;
            padding: 0 30px;
            color: #595F64;
            font-size: 22px;
          }
          .errorInput{
            position: absolute;
            top: 480px;
            left: 50%;
            transform: translateX(-50%);
            color: #FF4229;
            width: 446px;
            margin: 0 auto;
            text-align: left;
            display: block;
          }
          .cursorPoint{
            cursor: pointer;
            &:hover{
              color: #1E73FF;
            }
          }
        }
      }
      .footerIntrod{
        width: 470px;
        height: 67px;
        font-size: 19px;
        color: #7A7A7A;
        text-align: center;
        position: fixed;
        bottom: 100px;
        right: 300px;
        // left: 50%;
        // transform: translateX(-50%);
        text-align: right;
        white-space: nowrap;
      }
    }
    .registerWrapper {
      position: relative;
      .registerBlock{
        width: 1586px;
        // height: 1166px;
        background-color: #fff;
        border-radius: 5px;
        box-sizing: border-box;
        margin: 0 auto;
        .registerHeader{
          height: 78px;
          line-height: 78px;
          color: #1E73FF;
          text-align: left;
          padding: 0 52px;
          p{
            border-bottom: 1px solid #8F9DB1;
          }
          span{
            font-size: 22px;
            cursor: pointer;
          }
        }
        .registerBody{
          display: flex;
          justify-content: flex-start;
          padding-top: 50px;
          .normalTit{
            p{
              color: #595F64;
              font-size: 25px;
            }
            .registerSvg{
              width: 355px;
              height: 340px;
            }
          }
          .registerLabel{
            width: 355px;
            text-align: left;
            p{
              padding-left: 56px;
            }
            .registerSvg {
              padding-left: 24px;
            }
          }
          .registermain{
            margin-left: 130px;
            width: 1060px;
            font-size: 19px;
            text-align: left;
            height: calc(100vh - 130px - 100px - 50px - 80px); // 130 头顶， 10S0 底下， 50paddingtop 80 返回head
            overflow-y: scroll;
            &::-webkit-scrollbar {
              width: 12px;
            }
            
            /* 修改滚动条滑块 */
            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 10px;
            }
            .bigTit{
              font-size: 25px;
              color: #2441E0;
            }
            .iconSvg{
              fill: #ABABAB;
              color: #ABABAB;
              margin-left: 10px;
            }
            .label{
              margin: 20px 0 15px 0;
            }
            .inputItem{
              width: 565px;
              margin-bottom: 15px;
              display: flex;
              position: relative;
              input{
                height: 52px;
                font-size: 20px;
              }
              button{
                width: 230px;
                margin-left: 10px;
                height: 52px;
                font-size: 20px;
              }
              .errorInput{
                position: absolute;
                right: -480px;
                width: 480px;
                height: 52px;
                line-height: 52px;
                font-size: 16px;
                color: #FF4229;
                text-align: left;
                padding-left: 10px;
              }
            }
          }
          .submit{
            width: 564px;
            height: 52px;
            background-color: #3A72FE;
            color: #fff;
            margin-top: 102px;
            margin-bottom: 40px;
            font-size: 20px;

          }
        }
        .registerSuccess{
          width: 564px;
          padding-top: 194px;
          .registerBlock{
            width: 100%;
            p{
              text-align: center;
            }
            .regCircleBlock{
              width: 66px;
              height: 66px;
              border-radius: 33px; 
              border: 1px solid #3A72FE;
              color: #3A72FE;
              line-height: 66px;
              text-align: center;
              margin: 0 auto 34px;
              
              span{
                width: 100%;
                height: 100%;
                vertical-align: top;
                svg{
                  display: inline-block;
                  width: 50%;
                  height: 50%;
                  margin-top: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}