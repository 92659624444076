:global{
  .ant-modal{
    top: 20vh !important;
  }
  .Edu-block{
    width: 2470px;
    display: flex;
    margin: 0 auto;
    padding: 106px 0 40px 0;
    height: 100vh;
    box-sizing: border-box;
    justify-content: space-between;
    .left-bar{
      width: 310px;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      .item{
        height: 204px;
        cursor: pointer;
        color: #8F95B1;
        box-sizing: border-box;
        padding-top: 146px;
        font-size: 22px;
        text-align: center;
        position: relative;
      }
      .item::after{
        content: '';
        width: 80%;
        height: 1PX;
        background-color: #D7DBE7;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        bottom: -1px;
      }
      .register{
        position: relative;
        svg{
          width: 60px;
          height: 70px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          fill: #D7DBE7;
        }
      }
      .groupManager-left{
        svg{
          width: 80px;
          height: 70px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          fill: #D7DBE7;
        }
      }
      .active{
        background-color: #3A72FE;
        color: #fff;
        svg{
          fill: #fff;
        }
      }
    }
    
    .mainer{
      width: 2150px;
      margin-left: 10px;
      // background-color: #fff;
      border-radius: 10px;
    }
    
    
  }
  .ant-message{
    transform: scale(1.5) translateY(40px);
  }
}